import MetisMenu from 'metismenujs'

const bodyElement = document.querySelector('body');

/** 
 * Initlizes the menu - top and sidebar
*/
const initMenu = function () {
    const menu = new MetisMenu("#side-menu");
    const buttonMenuMobile = document.querySelector('.button-menu-mobile');

    buttonMenuMobile.addEventListener('click', function (event) {
        event.preventDefault();
        bodyElement.classList.toggle('sidebar-enable');

        if (window.innerWidth >= 768) {
            bodyElement.classList.toggle('enlarged');
        } else {
            bodyElement.classList.remove('enlarged');
        }
    });

    // activate the menu in left side bar based on url
    // Get all links inside the side menu
    const sideMenuLinks = document.querySelectorAll("#side-menu a");

    // Get the current URL without query parameters or hash
    const currentUrl = window.location.href.split(/[?#]/)[0];

    // Loop through each link
    sideMenuLinks.forEach(link => {
    if (link.href === currentUrl) {
        // Add 'active' class to the link
        link.classList.add("active");

        // Add 'mm-active' class to the parent li of the link
        link.parentElement.classList.add("mm-active");

        // Add 'mm-show' class to the grandparent ul of the link
        link.parentElement.parentElement.classList.add("mm-show");

        // Add 'active' class to the previous sibling anchor of the grandparent ul
        const prevSiblingAnchor = link.parentElement.parentElement.previousElementSibling;
        if (prevSiblingAnchor && prevSiblingAnchor.tagName === "A") {
            prevSiblingAnchor.classList.add("active");
        }

        // Add 'mm-active' class to the great-grandparent li of the link
        link.parentElement.parentElement.parentElement.classList.add("mm-active");

        // Add 'mm-show' class to the great-great-grandparent ul of the link
        link.parentElement.parentElement.parentElement.parentElement.classList.add("mm-show");

        // Add 'mm-active' class to the great-great-great-grandparent li of the link
        link.parentElement.parentElement.parentElement.parentElement.parentElement.classList.add("mm-active");
    }
    });
}

/**
 * Initializes the layout - with broad sidebar or compact sidebar
 */
function initLayout() {
    if (window.innerWidth <= 1024) {
        bodyElement.classList.add('enlarged');
    } else {
        if (bodyElement.getAttribute('data-keep-enlarged') !== 'true') {
            bodyElement.classList.remove('enlarged');
        }
    }
}

initLayout();
initMenu();
  
// On window resize, make menu flipped automatically
window.addEventListener('resize', function () {
    initLayout();
});
<template>
    <div class="col-12" v-if="user">
        <div class="card mb-3 card-body">
            <h4 class="card-title">{{user.name}}</h4>
            <div class="card-text mb-3">
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <img :src="'https://voxvivarotterdam.s3.eu-central-1.amazonaws.com/profile-pictures/' + user.profile_pic" style="max-width: 100%;" alt="Profiel foto">
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12">
                        <ul>
                            <li><label>E-mailadres:</label> {{ user.email }}</li>
                            <li><label>Gemaakt op:</label> {{ new Date(user.created_at).toLocaleString("nl-NL") }}</li>
                            <li><label>Geboortedatum:</label> {{ formatDate(user.birthdate * 1000) }}</li>
                            <li><label>Telefoonnummer:</label> {{ user.phone }}</li>
                            <li><label>Woonplaats:</label> {{ user.residence }}</li>

                            <el-form-item label="Geslacht" prop="gender">
                                <el-select v-model="gender" @change="editGender()">
                                    <el-option label="Selecteer een geslacht" :value="''" :disabled="true"></el-option>
                                    <el-option label="Man" :value="'m'"></el-option>
                                    <el-option label="Vrouw" :value="'v'"></el-option>
                                </el-select>
                            </el-form-item>
                        </ul>
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12">
                        <ul v-if="user.studies[0]">
                            <li><label>Studie:</label> {{user.studies[0].study}}</li>
                            <li><label>Naam instelling:</label> {{user.studies[0].institution}}</li>
                            <li><label>Studie plaats:</label> {{user.studies[0].study_site}}</li>
                            <li><label>Startjaar studie:</label> {{user.studies[0].study_start}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <el-button type="success" :disabled="(gender == '')" @click="approveUser()">Goedkeuren</el-button>
                    <el-button type="danger" :disabled="(gender == '')" @click="rejectUser()">Afkeuren</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
 
<script setup lang="ts">
import { ref } from 'vue'
import type { PropType } from 'vue'
import { ElNotification } from 'element-plus'
import http from "../../libs/axios"
import moment from 'moment'

interface User {
    id: number,
    name: string,
    profile_pic: string,
    email: string,
    birthdate: number,
    phone: string,
    residence: string,
    gender: string,
    studies: Array<{
        study: string,
        institution: string,
        study_site: string,
        study_start: number
    }>,
    created_at: string
}

const props = defineProps({
    'user': Object as PropType<User>
});

let gender = ref('')

async function editGender() {
    if (props.user) {
        await http.put('/leden/registraties/geslacht/' + props.user.id, {'gender': gender.value})
            .then(() => savedSuccesNotification())
            .catch(() => {return false})
    }
}

async function approveUser() {
    if (props.user) {
        await http.get('/leden/registraties/goedkeuren/' + props.user.id)
            .then(() => window.location.reload())
            .catch(() => {return false})
    }
}

async function rejectUser() {
    if (props.user) {
        await http.get('/leden/registraties/afkeuren/' + props.user.id)
            .then(() => window.location.reload())
            .catch(() => {return false})
    }
}

function savedSuccesNotification() {
    ElNotification({
        title: 'Opgeslagen',
        message: 'Geslacht succesvol opgeslagen!',
        type: 'success'
    });
}

function formatDate (date: number) {
    return moment(date).format('DD/MM/Y');
}
</script>
<template>
    <div class="question-box">
        <div class="drag-question" :class="{visible: editMode}">
            <i class="mdi mdi-sort"></i>
        </div>
        <div class="edit-mode" v-if="editMode">
            <div class="row question">
                <div class="col-8">
                    <el-form-item prop="question_name">
                        <el-input v-model="questionForm.name" placeholder="Vraag"></el-input>
                    </el-form-item>

                    <el-form-item prop="question_description" v-if="questionForm.show_description">
                        <el-input v-model="questionForm.description" placeholder="Omschrijving"></el-input>
                    </el-form-item>
                </div>

                <div class="col-4">
                    <el-form-item prop="question_type">
                        <el-select v-model="questionForm.type" placeholder="Type vraag" @change="changeQuestionType($event)">
                            <template #prefix>
                                <i class="mdi el-input__icon" :class="optionTypes[questionForm.type].icon"></i>
                            </template>
                            <el-option
                                v-for="option in optionTypes"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value">
                                <span style="float: left; padding-right: 10px;"><i class="mdi" :class="'mdi ' + option.icon"></i></span>
                                <span style="float: left">{{ option.label }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            
            <component 
                :is="optionTypes[questionForm.type].component" 
                v-model:options="questionForm.options"
                @add-option="optionAdded"
                @remove-option="optionRemoved"
                @update-order="optionOrderUpdated"
                :previewMode="false"
            ></component>

            <div class="question-footer">
                <span class="delete" @click="removeQuestion()">
                    <i class="mdi mdi-trash-can"></i>
                </span>
                <span class="required">
                    <el-switch
                        v-model="questionForm.required"
                        inactive-text="Verplicht">
                    </el-switch>
                </span>
                <span class="description">
                    <el-switch
                        v-model="questionForm.show_description"
                        inactive-text="Toon beschrijving">
                    </el-switch>
                </span>
            </div>
        </div>

        <div class="preview-mode" v-else>
            <h4 class="question-title">
                <span class="text-danger" v-if="questionForm.required">*</span>
                {{ questionForm.name }}
            </h4>
            <h6 class="question-description" v-if="questionForm.show_description">{{questionForm.description}}</h6>
            <component 
                :is="optionTypes[questionForm.type].component" 
                :options="questionForm.options"
                :previewMode="true"
            ></component>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { Component } from 'vue'
import CheckboxAnswer from './answertypes/checkboxAnswer.vue'
import RadioAnswer from './answertypes/radioAnswer.vue'
import RadioPriceAnswer from './answertypes/radioPriceAnswer.vue'
import TextAnswer from './answertypes/textAnswer.vue'

interface OptionType {
    label: string,
    value: string,
    component: Component,
    icon: string
}

interface OptionTypes {
    [key: string]: OptionType
}

const props = defineProps(['question', 'editMode'])
const emit = defineEmits(['update:question', 'remove-question'])

const optionTypes : OptionTypes = {
    text: { 
        label: 'Tekst',
        value: 'text',
        component: TextAnswer,
        icon: 'mdi-text' 
    },
    radio: { 
        label: 'Enkele keuze',
        value: 'radio',
        component: RadioAnswer,
        icon: 'mdi-radiobox-marked' 
    },
    checkbox: { 
        label: 'Meerkeuze',
        value: 'checkbox',
        component: CheckboxAnswer,
        icon: 'mdi-checkbox-marked' 
    },
    radio_price: { 
        label: 'Prijs op basis van keuze',
        value: 'radio_price',
        component: RadioPriceAnswer,
        icon: 'mdi-currency-eur' 
    },
}

const questionForm = computed({
    get() {
        return props.question
    },
    set(value) {
        emit('update:question', value)
    }
})

function removeQuestion() {
    emit('remove-question')
}

function changeQuestionType(type : string) {
    if (!questionForm.value.options || questionForm.value.options.length == 0) {
        questionForm.value.options = []
    }
    
    if (type == 'text') {
        questionForm.value.options = false
    }
}

const optionAdded = (option : {}) => {
    questionForm.value.options.push(option)
}

const optionRemoved = (index : number) => {
    questionForm.value.options.splice(index, 1)
}

const optionOrderUpdated = (oldIndex : number, newIndex: number) => {
    // const item = questionForm.value.options.splice(oldIndex, 1)[0]
    // questionForm.value.options.splice(newIndex, 0, item)

    // questionForm.value.options = questionForm.value.options

    // console.log(oldIndex)
    // console.log(newIndex)
    // questionForm.value.options = orderOptions
}

</script>

<style lang="scss">
.question-box {
    background-color: #fff;
    padding: 1.25rem 10px 0;
    box-shadow: 0px 0px 35px 0px rgb(73 80 87 / 15%);
    margin-bottom: 24px;
    border-radius: 0.25rem;

    .question {
        padding: 0 1rem;
    }

    .question-footer {
        margin: 1.75rem 1rem 0;
        padding: 10px 0;
        border-top: 1px solid #DCDFE6;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > span {
            border-right: 1px solid #DCDFE6;
            padding: 0 1.5rem;

            &.delete {
                font-size: 1.3rem;

                &:hover {
                    cursor: pointer;
                    color: #F56C6C;
                }
            }

            &:last-child {
                border-right: none;
                padding-right: 0;
            }
        }
    }
}
</style>
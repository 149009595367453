<template>
    <div class="options" v-if="!previewMode">
        <Sortable 
            :list="multipleOptions"
            itemKey="key"
            :options="dragOptions"
        >
            <template #item="{element, index}">
                <div class="option">
                    <span class="handle">
                        <i class="mdi mdi-sort" v-if="multipleOptions.length > 1"></i>
                    </span>
                    <span class="icon">
                        <i class="mdi mdi-radiobox-blank"></i>
                    </span>
                    <div class="input">                        
                        <el-input v-model="element.name" @blur="updateOptionLabel(index)"></el-input>
                    </div>
                    <div class="input suffix-input">           
                        <el-input type="number" 
                            v-model.number="element.price" 
                            step="0.5"
                        >
                        <template #prefix>
                                <i class="mdi mdi-currency-eur"></i>
                            </template>
                        </el-input>
                    </div>
                    <span class="remove" v-if="multipleOptions.length > 1">
                        <i class="mdi mdi-close" @click="removeOption(index)"></i>
                    </span>
                </div>
            </template>
        </Sortable>

        <div class="option new-option">
            <span class="handle"></span>
            <span class="icon">
                <i class="mdi mdi-radiobox-blank"></i>
            </span>
            <div class="input add-option" @click="addOption()">                        
                <span>Optie toevoegen (€€€)</span>
            </div>
        </div>
    </div>

    <div class="options" v-else>
        <div class="option" v-for="option in multipleOptions" :key="option.key">
            <span class="icon">
                <i class="mdi mdi-radiobox-blank"></i>
            </span>
            <div class="input">                        
                <span>{{option.name}}</span>
                <span> (&euro;{{option.price}})</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Sortable }  from 'sortablejs-vue3'

const props = defineProps(['options', 'previewMode'])
const emit = defineEmits(['addOption', 'removeOption'])

const multipleOptions = computed({
  get() {
    return props.options
  },
  set(value) {
    emit('addOption', value)
  }
})

const optionKey = ref(0)

if (props.options) {
    optionKey.value = props.options.length
}

const dragOptions = {
    animation: 200,
    group: "options",
    handle: ".handle",
    ghostClass: "ghost"
}

function addOption() {
    optionKey.value = optionKey.value + 1;

    const new_option = {
        name: 'Optie ' + (multipleOptions.value.length + 1),
        price: 0,
        key: optionKey.value
    }

    emit('addOption', new_option)
}

function removeOption (index : number) {
    emit('removeOption', index)
}

function updateOptionLabel (index : number) {
    if (multipleOptions.value[index].name == '')
        multipleOptions.value[index].name = 'Optie ' + (index + 1);
}
</script>
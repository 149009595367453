<template>
    <div>
        <el-table :data="discountCodes" :border="true" style="width: 100%">
            <el-table-column label="Naam" prop="name" />
            <el-table-column label="Gebruiker" prop="user.name"></el-table-column>
            <el-table-column label="Code" prop="code" />
            <el-table-column label="Verloopt op" prop="expires_at" :formatter="dateFormatter" ></el-table-column>
            <el-table-column label="Waarde" prop="discount_amount"></el-table-column>
            <el-table-column label="Gebruikt" prop="used"></el-table-column>
            <el-table-column label="Acties">
                <template #default="scope">
                    <button @click="removeCode(scope.row.id)" class="btn btn-danger btn-sm" v-if="!scope.row.used">Verwijder code</button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script setup lang="ts">
import http from "../../libs/axios"
import moment from 'moment'

import { DiscountCode } from '../../types/discountCode'
import { TableColumnCtx } from "element-plus";

const props = defineProps({
    'discountCodes': Array<[DiscountCode]>
})

function removeCode (id: number) {
    ElMessageBox.confirm('Weet je zeker dat je deze code wilt verwijderen?')
        .then(() => {
            if (id) {
                http.delete('/producten/kortingscodes/' + id)
                    .then(() => {
                        window.location.reload(); 
                    })
                    .catch(() => {return false});
            }
        })
        .catch(() => {});
}

const dateFormatter = (_row: DiscountCode, _column: TableColumnCtx<DiscountCode>, cellValue: number) => {
  return formatDate(cellValue);
}

function formatDate (date: number) {
    return moment(date).format('DD/MM/Y');
}
</script>
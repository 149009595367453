<template>
    <el-form :model="magazineForm" :rules="rules" ref="magazineFormRef" label-width="120px" label-position="top" @submit.prevent="submitForm(magazineFormRef)">
        <div class="card-box">
            <h3 class="header-title mt-0">Gegevens {{ (magazineForm && magazineForm.id ? magazineForm.name : 'product') }}</h3>
            <p class="text-muted font-13 mb-2">
                Alle velden met een <span class="required">*</span> zijn verplicht
            </p>

            <input type="hidden" v-model="magazineForm.id" disabled readonly>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Naam" prop="name">
                        <el-input v-model="magazineForm.name" placeholder="Bijv: Vox krant 2023-1"></el-input>
                    </el-form-item>
                </div>

                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Korte beschrijving" prop="description">
                        <editor 
                            :api-key="tinymceApiKey"
                            cloud-channel="6"
                            :init="{
                            plugins: 'lists',
                            menubar: false,
                            toolbar: [
                                { name: 'history', items: [ 'undo', 'redo' ] },
                                { name: 'formatting', items: [ 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'underline' ] },
                                { name: 'alignment', items: [ 'alignleft', 'aligncenter', 'alignright', 'alignjustify' ] },
                                { name: 'other', items: ['removeformat ']}
                            ]
                            }"
                            v-model="magazineForm.description">
                        </editor>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <el-form-item label="Datum uitgebracht" prop="date_published">    
                        <el-date-picker 
                            v-model="datePublished"
                            type="date"
                            format="DD/MM/YYYY"
                            value-format="x"
                            placeholder="Kies datum"
                        >
                        </el-date-picker>
                    </el-form-item>
                </div>

                <div class="col-md-6">
                    <el-form-item label="Krant afbeelding" prop="thumbnail">
                        <el-input v-model="magazineForm.thumbnail" placeholder="Kies een thumbnail" readonly>
                            <template #append>
                                <a class="btn" @click="openFileManager('images')" style="color: black;">Kies afbeelding</a>
                            </template>
                        </el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-12">
                    <el-form-item label="Krant pdf" prop="file_path">
                        <el-input v-model="magazineForm.file_path" placeholder="Kies een krant" readonly>
                            <template #append>
                                <a class="btn" @click="openFileManager('files')" style="color: black;">Kies bestand</a>
                            </template>
                        </el-input>
                    </el-form-item>
                </div>
            </div>
        </div>

        <el-form-item>
            <el-button type="primary" @click="submitForm(magazineFormRef)">
                Opslaan
            </el-button>
        </el-form-item>
    </el-form>
</template>
 
<script setup lang="ts">
import { ref, reactive, computed } from 'vue'
import type { PropType } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import Editor from '@tinymce/tinymce-vue'
import Flmngr from "flmngr"
import http from "../../libs/axios"

interface Magazine {
    id: number | null,
    name: string,
    description: string,
    thumbnail: string,
    file_path: string,
    date_published: number
}

const props = defineProps({
    'magazine': Object as PropType<Magazine>
})

const flmngrApiKey = import.meta.env.VITE_FLMNGR_API_KEY
const tinymceApiKey = import.meta.env.VITE_TINYMCE_API_KEY
const magazineFormRef = ref<FormInstance>()
const magazineForm: Magazine = reactive({
    id: null,
    name: '',
    description: '',
    thumbnail: '',
    file_path: '',
    date_published: 0,
    ...props.magazine
})

const rules = reactive<FormRules>({
    name: [
        { required: true, message: "Vul de naam in van de krant", trigger: "blur"},
        { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" }
    ],
    description: [
        { required: true, message: "Vul een beschrijving in voor de krant", trigger: "blur"}
    ],
    thumbnail: [
        { required: true, message: "Voeg een plaatje toe voor de krant", trigger: "blur"}
    ],
    file_path: [
        { required: true, message: "Voeg een pdf van de krant toe", trigger: "blur"}
    ],
    date_published: [
        { type: "number", required: true, message: "Kies een datum", trigger: "change" },
        { type: "number", min: 1, message: "Kies een datum", trigger: "blur" }
    ],
})

Flmngr.load({
    apiKey: flmngrApiKey, // default free key
})

const datePublished = computed({
    get() {
        return magazineForm.date_published * 1000;
    },
    set( newValue ) {
        magazineForm.date_published = newValue / 1000;
    }
});

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    await formEl.validate((valid, fields) => {
        if (valid) {
            if (magazineForm.id === null) {
                http.post('/kranten', magazineForm)
                    .then(response => {
                        savedSuccesNotification()

                        // Set current url to edit
                        let newUrl = window.location.origin + "/kranten/" + response.id + "/bewerken"
                        window.location.assign(newUrl)
                        window.open(newUrl, "_top")
                    })
                    .catch(() => {return false})
            } else {
                http.put('/kranten/' + magazineForm.id, magazineForm)
                    .then(() => savedSuccesNotification())
                    .catch(() => {return false})
            }
        } else {
            return false
        }
    });
}

function savedSuccesNotification() {
    ElMessage({
        title: 'Opgeslagen',
        message: 'Krant succesvol opgeslagen!',
        type: 'success'
    })
}

function openFileManager(type = 'images') {
    let urlFileManager = 'https://admin.voxvivarotterdam.nl/kranten/bestanden/afbeeldingen';
    let urlFiles = '/storage/magazines/images';
    let acceptExtensions = ["png", "jpeg", "jpg", "webp", "gif"];

    if (type == 'files') {
        urlFileManager = 'https://admin.voxvivarotterdam.nl/kranten/bestanden/documenten';
        urlFiles = '/storage/magazines/files';
        acceptExtensions = ["pdf"];
    }

    Flmngr.open({
        urlFileManager: urlFileManager, // server
        urlFiles: urlFiles, // file storage
        isMultiple: false,
        showMaximizeButton: false,
        onFinish: (files) => {
            if (type == 'images') {
                magazineForm.thumbnail = files[0].url;
            } else if (type == 'files') {
                magazineForm.file_path = files[0].url;
            }
        },
        acceptExtensions: acceptExtensions,
    });
}

</script>
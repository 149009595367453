<template>
    <el-form :model="formForm" :rules="rules" ref="formFormRef" label-width="120px" label-position="top" @submit.prevent="submitForm(formFormRef)">
        <div class="card-box">
            <h3 class="header-title mt-0">Gegevens {{ (formForm && formForm.id ? formForm.name : 'formulier') }}</h3>
            <p class="text-muted font-13 mb-2">
                Alle velden met een <span class="required">*</span> zijn verplicht
            </p>

            <input type="hidden" v-model="formForm.id" disabled readonly>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Naam" prop="name">
                        <el-input v-model="formForm.name" placeholder="Bijv: Voorjaarsweekend"></el-input>
                    </el-form-item>
                </div>

                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Beschrijving" prop="description">
                        <el-input type="textarea" v-model="formForm.description" required></el-input>
                    </el-form-item>
                </div>
            </div>
        </div>

        <h5 class="header-title mt-0">Velden formulier</h5>
        
        <div class="questions" v-if="formForm.questions.length > 0">
            <Sortable 
                :list="formForm.questions"
                :options="dragOptions"
                group="questions"
                handle=".drag-question"
                @end="updateOrder()"
                item-key="key"
            >
                <!--eslint-disable-next-line-->
                <template #item="{element, index}">
                    <div @click="switchEditableQuestion(index)">
                        <question
                            v-model:question="formForm.questions[index]"
                            :editMode="(editableQuestion == index)"
                            v-on:remove-question="removeQuestion(index)"
                        ></question>
                    </div>
                </template>
            </Sortable>

            <el-button type="success" class="mb-3" @click="addQuestion()">
                Vraag toevoegen
            </el-button>
        </div>

        <div v-if="errors">
            <span v-for="(error, key) in errors" :key="key">{{ error }}</span>
        </div>

        <el-form-item>
            <el-button type="primary" class="btn btn-primary waves-effect waves-light" @click="submitForm(formFormRef)">
                Opslaan
            </el-button>
        </el-form-item>
    </el-form>
</template>
 
<script setup lang="ts">
import { ref, reactive } from 'vue'
import type { PropType } from 'vue'
import { ElNotification } from 'element-plus'
import type { FormInstance, FormRules } from 'element-plus'
import http from "../../libs/axios"
import { Sortable }  from 'sortablejs-vue3'
import question from './question.vue'

interface Form {
    id: number | null,
    name: string,
    description: string,
    questions: Array<Question>
}

interface Question {
    id: number | null,
    order: number,
    key: number,
    name: string,
    description: string,
    show_description: boolean,
    type: string,
    required: boolean,
    options: Array<Option> | boolean
}

interface Option {
    name: string,
    key: number,
    price: number
}

const props = defineProps({
    'form': Object as PropType<Form>
})
const formFormRef = ref<FormInstance>()
const formForm: Form = reactive({
    id: null,
    name: '',
    description: '',
    questions: [],
    ...props.form
});

const rules = reactive<FormRules>({
    name: [
        { required: true, message: "Vul de naam in van het formulier", trigger: "blur"},
        { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" }
    ],
    description: [
        { required: true, message: "Vul een beschrijving in voor het formulier", trigger: "blur"}
    ]
})

const errors = reactive([])
const editableQuestion = ref(0)
const questionKey = ref(0)
const dragOptions = {
    animation: 200,
    group: "questions",
    handle: ".drag-question",
    ghostClass: "ghost"
}

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    await formEl.validate((valid, fields) => {
        if (valid) {
            if (formForm.id === null) {
                http.post('/formulieren', formForm)
                    .then(response => {
                        savedSuccesNotification();

                        // Set current url to edit
                        let newUrl = window.location.origin + "/formulieren/" + response.id + "/bewerken";
                        history.replaceState({}, '', newUrl);

                        formForm.id = response.id;
                    })
                    .catch(() => {return false})
            } else {
                http.put('/formulieren/' + formForm.id, formForm)
                    .then(() => savedSuccesNotification())
                    .catch(() => {return false})
            }
        } else {
            return false;
        }
    });
}

function savedSuccesNotification() {
    ElNotification({
        title: 'Opgeslagen',
        message: 'Formulier succesvol opgeslagen!',
        type: 'success'
    });
}

function addQuestion() {
    updateOrder();
    questionKey.value = questionKey.value + 1;

    if (!formForm.questions) {
        formForm.questions = [];
    }

    formForm.questions.push({
        id: null,
        order: formForm.questions.length,
        key: questionKey.value,
        name: 'Vraag',
        description: '',
        show_description: false,
        type: 'radio',
        required: false,
        options: [
            {
                name: 'Optie 1',
                key: 1,
                price: 0
            }
        ]
    });

    switchEditableQuestion(formForm.questions.length - 1);
}

function removeQuestion (index: number) {
    ElMessageBox.confirm('Weet je zeker dat je deze vraag wilt verwijderen?')
        .then(() => {
            formForm.questions.splice(index, 1);

            updateOrder();
        })
        .catch(() => {});
}

function updateOrder () {
    formForm.questions.map((question, i) => {
        question.order = i;
    });
}

function switchEditableQuestion (questionIndex: number) {
    editableQuestion.value = questionIndex;
}

if (!formForm.questions || formForm.questions.length == 0) {
    addQuestion();
}

questionKey.value = formForm.questions.length;

</script>
import { createApp, h } from 'vue'
import { convertDataSet } from './helper'

import CreateEvent from '../components/event/createEvent.vue'
import Registrations from '../components/event/registrations.vue'
import Wanbetalers from '../components/event/wanbetalers.vue'
import AddRegistration from '../components/event/addRegistration.vue'

if (document.getElementById('createEventForm')) {
  const app1 = createApp({
    render() {
      return h(CreateEvent, convertDataSet('createEventForm'))
    }
  })

  app1.mount('#createEventForm')
}

if (document.getElementById('registrationsEvent')) {
  const app2 = createApp({
    render() {
      return h(Registrations, convertDataSet('registrationsEvent') )
    }
  })

  app2.mount('#registrationsEvent')
}

if (document.getElementById('addRegistrationEvent')) {
  const app3 = createApp({
    render() {
      return h(AddRegistration, convertDataSet('addRegistrationEvent') )
    }
  })

  app3.mount('#addRegistrationEvent')
}

if (document.getElementById('registrationsWanbetalers')) {
  const app4 = createApp({
    render() {
      return h(Wanbetalers, convertDataSet('registrationsWanbetalers') )
    }
  })

  app4.mount('#registrationsWanbetalers')
}
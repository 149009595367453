<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card-box">
        <h3 class="header-title mt-0">Gegevens {{(photoForm.id != null ? photoForm.name : 'album')}}</h3>
        <p class="text-muted font-13 mb-2">
          Alle velden met een <span class="required">*</span> zijn verplicht<br/>
          Op opslaan klikken is alleen nodig als de bovenste velden worden aangepast.
        </p>

        <el-form :model="photoForm" :rules="rules" ref="photoFormRef" label-width="120px" label-position="top" @submit.prevent="submitForm(photoFormRef)">
          <input type="hidden" v-model="photoForm.id" disabled readonly>
          <div class="p-20">
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <el-form-item label="Naam" prop="name">
                  <el-input v-model="photoForm.name" placeholder="Bijv: Lezing 3"></el-input>
                </el-form-item>
              </div>

              <div class="col-md-4 col-sm-12">
                <el-form-item label="Categorie" prop="category_id">
                  <el-select v-model="photoForm.category_id" placeholder="Selecteer een album categorie">
                      <el-option 
                          v-for="photo_category in photoCategories"
                          :label="photo_category.name"
                          :value="photo_category.id" 
                          :key="photo_category.id"
                      ></el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="col-md-4 col-sm-12">
                <el-form-item label="Datum" prop="date">
                  <el-date-picker
                    v-model="photoForm.date"
                    type="date"
                    placeholder="Kies een datum"
                    format="YYYY/MM/DD"
                    value-format="YYYY-MM-DD"
                  />
                </el-form-item>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-sm-12">
                <el-form-item label="Jaarmap" prop="parent_folder_id">
                  <el-select v-model="photoForm.parent_folder_id" placeholder="Selecteer een jaarmap">
                      <el-option 
                          v-for="parent_category in parentCategories"
                          :label="parent_category.name"
                          :value="parent_category.id" 
                          :key="parent_category.id"
                      ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>

          <el-form-item>
            <el-button type="primary" class="btn btn-primary waves-effect waves-light" @click="submitForm(photoFormRef)">
              Opslaan
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="card-box" v-if="folder?.category_id != 1">
        <h3 class="header-title mt-0">Foto's in dit album</h3>
        <p class="text-muted font-13 mb-2">
          Foto's worden verkleind naar maximaal 2560x1440. Een foto met dezelfde naam wordt overgeschreven, ook al staan ze er na het uploaden allebei nog in. De "oude" foto gaat weg als de pagina is herladen.
        </p>
        
        <template v-if="folder">
          <el-upload
            v-model:file-list="folder.photos"
            :action="'/fotos/' + folder.id + '/upload'"
            :headers="uploadHeaders"
            list-type="picture-card"
            :on-remove="handleRemove"
            :with-credentials="false"
            drag
            multiple
          >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
              Drop foto hier of <em>klik om te uploaden!</em>
            </div>
          </el-upload>
          <a :href="'/fotos/' + folder.id + '/download'" class="btn btn-primary waves-effect waves-light mt-3">
            Download alle foto's
          </a>
        </template>
        <template v-else><p>Je moet een fotomap opslaan voordat je foto's kan toevoegen.</p></template>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { UploadFilled } from '@element-plus/icons-vue'
import type { PropType } from 'vue'
import type { FormInstance, FormRules, UploadProps, UploadUserFile } from 'element-plus'
import http from "../../libs/axios"

interface Folder {
  id: number | null,
  name: string,
  category_id: number | null,
  date: string,
  active: boolean,
  photos: Array<UploadUserFile>
}

interface PhotoCategory {
  id: number,
  name: string
}

const props = defineProps({
  'folders' : Object,
  'folder': Object as PropType<Folder>,
  'parentCategories': Object as PropType<PhotoCategory>,
  'photoCategories': Object as PropType<PhotoCategory>,
  'csrfToken' : String
})

const uploadHeaders = new Headers()

if (props.csrfToken) {
  uploadHeaders.set('X-CSRF-TOKEN', props.csrfToken)
}

const photoFormRef = ref<FormInstance>()
const photoForm: Folder = reactive({
    id: null,
    name: '',
    category_id: null,
    parent_folder_id: null,
    date: '',
    photos: [],
    active: false,
    ...props.folder
})

const rules = reactive<FormRules>({
    name: [
      { required: true, message: "Vul een naam in voor deze map", trigger: "blur"},
      { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" }
    ],
    category_id: [
      { required: true, message: "Kies een categorie voor deze map", trigger: "blur"},
    ],
    date: [
      { required: true, message: "Kies een datum", trigger: "change"}
    ]
})

const handleRemove: UploadProps['onRemove'] = (uploadFile : any) => {
  http.delete('/fotos/foto/' + uploadFile.id)
    .then(() => ElNotification({
        title: 'Verwijderd',
        message: 'Foto is verwijderd!',
        type: 'success'
    }))
    .catch(() => {
      photoForm.photos.push(uploadFile)
      return false
    })
}

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    await formEl.validate((valid, fields) => {
        if (valid) {
            if (photoForm.id === null) {
                http.post('/fotos', photoForm)
                    .then(response => {
                        savedSuccesNotification()

                        // Set current url to edit
                        let newUrl = window.location.origin + "/fotos/" + response.id + "/bewerken"
                        history.replaceState({}, '', newUrl)

                        photoForm.id = response.id
                    })
                    .catch(() => {return false})
            } else {
                http.put('/fotos/' + photoForm.id, photoForm)
                    .then(() => savedSuccesNotification())
                    .catch(() => {return false})
            }
        } else {
            return false;
        }
    });
}

function savedSuccesNotification() {
    ElNotification({
        title: 'Opgeslagen',
        message: 'Map succesvol opgeslagen!',
        type: 'success'
    })
}

</script>
  
<style lang="scss">
.el-upload.el-upload--picture-card {
  --el-upload-picture-card-size: auto;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  order: -1;  
}
</style>
import { createApp, h } from 'vue'
import { convertDataSet } from './helper'

import PhotosTree from '../components/photo/photosTree.vue'
import PhotosUpload from '../components/photo/photosUpload.vue'

if (document.getElementById("photosTree")) {
    const app1 = createApp({
        render() {
          return h(PhotosTree, convertDataSet('photosTree'))
        }
    })
    
    app1.mount('#photosTree')
}

if (document.getElementById("photosUpload")) {
  const app2 = createApp({
      render() {
        return h(PhotosUpload, convertDataSet('photosUpload'))
      }
  })
  
  app2.mount('#photosUpload')
}
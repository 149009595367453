<template>
    <div>
        <button class="btn btn-primary" role="button" @click="showDialog = true">Code genereren</button>

        <el-dialog
            title="Code genereren"
            width="500px"
            :show-close="true"
            :close-on-click-modal="false"
            :model-value="showDialog"
            :before-close="handleClose">
            <el-form :model="codeForm" :rules="rules" ref="codeFormRef" label-width="120px" label-position="top" @submit.prevent="submitForm(codeFormRef)" v-if="!codeIsGenerated">
                <el-form-item label="Naam" prop="name">
                    <el-input v-model="codeForm.name" placeholder="Bijv: Korting bijdrage ThemaCie"></el-input>
                </el-form-item>

                <el-form-item label="Kortingsbedrag in euro" prop="discount_amount">
                    <el-input type="number" v-model.number="codeForm.discount_amount" step="0.01"></el-input>
                </el-form-item>

                <el-form-item label="Kies een of meer leden" prop="user_id">
                    <el-select v-model="codeForm.user_id" multiple filterable :reserve-keyword="false" placeholder="Selecteer lid" style="width: 100%;">
                        <el-option
                            v-for="member in members"
                            :key="member.id"
                            :label="member.name"
                            :value="member.id"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
            
            <div v-if="codeIsGenerated">
                <div v-for="generatedCode in generatedCodes">
                    <p>Gegenereerde code voor {{ generatedCode.user_id }} is:</p>
                    <code><h1>{{ generatedCode.code }}</h1></code>

                    <el-button v-if="generatedCode.code" @click="copyCode(generatedCode.code)">Kopiëer code</el-button>
                </div>

            </div>

            <template #footer>
                <el-button @click="handleClose()" v-html="(!codeIsGenerated ? 'Annuleren' : 'Sluiten')"></el-button>
                <el-button type="primary" @click="submitForm(codeFormRef)" v-if="!codeIsGenerated">Genereren</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue'
import type { Ref } from 'vue'
import { ElNotification } from 'element-plus'
import type { FormInstance, FormRules } from 'element-plus'
import http from "../../libs/axios"

import { DiscountCode } from '../../types/discountCode'
import { AxiosResponse } from 'axios'

const props = defineProps({
    'members': Array<[]>
})

const showDialog: Ref<boolean> = ref(false)
const codeIsGenerated: Ref<boolean> = ref(false)
const generatedCodes: Ref<Array<DiscountCode>> = ref([])

const codeFormRef = ref<FormInstance>()
const codeForm: DiscountCode = reactive({
    id: null,
    user_id: null,
    name: '',
    used: false,
    discount_amount: 7.50,
    expires_at: null
})

const rules = reactive<FormRules>({
    name: [
        { required: true, message: "Vul de naam in voor de code", trigger: "blur"},
        { min: 5, message: "Vul minstens 5 letters in", trigger: "blur" }
    ],
    user_id: [
        { required: true, message: "Kies een lid", trigger: "blur"},
        { type: "array", min: 1, message: "Kies minstens 1 lid", trigger: "blur" }
    ],
    discount_amount: [
        { required: true, message: "Vul een bedrag in", trigger: "blur"},
        { type: "number", min: 1, max: 50, message: "Bedrag tussen de 1 en 50 euro", trigger: "blur" }
    ],
})

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    await formEl.validate((valid, fields) => {
        if (valid) {
            http.post('/producten/kortingscodes', codeForm)
                .then((response) => {
                    savedSuccesNotification()
                    codeIsGenerated.value = true
                    generatedCodes.value = response
                })
                .catch(() => {return false})
        } else {
            return false
        }
    });
}

function copyCode(code: string) {
    navigator.clipboard.writeText(code)

    ElNotification({
        title: 'Gekopieërd',
        message: 'Code succesvol gekopieërd!',
        type: 'success'
    })
}

function handleClose() {
    showDialog.value = false

    if (codeIsGenerated.value) {
        setTimeout(() => {
            document.location.reload()
        }, 500)
    }
}

function savedSuccesNotification() {
    ElNotification({
        title: 'Opgeslagen',
        message: 'Code succesvol gegenereerd!',
        type: 'success'
    })
}
</script>
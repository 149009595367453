<template>
    <div>
        <el-form :model="notificationForm" ref="notificationFormRef" label-width="120px" label-position="top"
            @submit.prevent="submitForm(notificationFormRef)">
            <el-form-item label="Titel" prop="title" required>
                <el-input v-model="notificationForm.title" placeholder="Bijv: Nieuwe functie!"></el-input>
            </el-form-item>

            <el-form-item label="Bericht" prop="body" required>
                <el-input v-model="notificationForm.body" type="textarea" />
            </el-form-item>

            <el-form-item label="Action button text" prop="action_text">
                <el-input v-model="notificationForm.action_text" />
            </el-form-item>

            <el-form-item label="Action button URL" prop="action_url">
                <el-input v-model="notificationForm.action_url" type="url" />
            </el-form-item>

        </el-form>

        <el-button type="primary" @click="submitForm(notificationFormRef)">Versturen</el-button>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue'
import { ElNotification } from 'element-plus'
import type { FormInstance } from 'element-plus'
import http from "../../libs/axios"

interface Notification {
    title: string,
    body: string,
    action_text: string,
    action_url: string,
}

const notificationFormRef = ref<FormInstance>()
const notificationForm: Notification = reactive({
    title: '',
    body: '',
    action_text: '',
    action_url: '',
})


const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    await formEl.validate((valid, fields) => {
        if (valid) {
            http.post('/notificaties', notificationForm)
                .then((response) => {
                    formEl.resetFields()
                    savedSuccesNotification()
                })
                .catch(() => { return false })
        } else {
            return false
        }
    });
}


function savedSuccesNotification() {
    ElNotification({
        title: 'Verstuurd',
        message: 'Notificatie succesvol verstuurd!',
        type: 'success'
    })
}
</script>
<template>
    <el-form :model="pageForm" :rules="rules" ref="pageFormRef" label-width="120px" label-position="top" @submit.prevent="submitForm(pageFormRef)">
        <input type="hidden" v-model="pageForm.id" disabled readonly>
        <div class="p-20">
            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <el-form-item label="Naam" prop="Name">
                        <el-input v-model="pageForm.name" disabled readonly></el-input>
                    </el-form-item>
                </div>

                <div class="col-md-4 col-sm-12">
                    <el-form-item label="Titel" prop="title">
                        <el-input v-model="pageForm.title" disabled readonly></el-input>
                    </el-form-item>
                </div>

                <div class="col-md-4 col-sm-12">
                    <el-form-item label="URL" prop="url">
                        <el-input v-model="pageForm.url" type="url" disabled readonly></el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <el-form-item label="Meta beschrijving" prop="meta">
                        <el-input v-model="pageForm.meta" required></el-input>
                    </el-form-item>
                </div>
            </div>
        </div>

        <component 
            v-if="['about', 'agenda', 'home', 'management', 'terms', 'privacy'].includes(pageForm.name)"
            :is="pageComponent"
            v-model:content="pageForm.content"
            @add-person="addPerson"
            @remove-person="removePerson"
            @update-photo="updatePhoto"
        ></component>

        <el-form-item>
            <el-button type="primary" class="btn btn-primary waves-effect waves-light" @click="submitForm(pageFormRef)">
                Opslaan
            </el-button>
        </el-form-item>
    </el-form>
</template>
 
<script setup lang="ts">
import { ref, reactive, defineAsyncComponent } from 'vue'
import type { PropType } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import http from "../../libs/axios"

interface Page {
    id: number | null,
    name: string,
    title: string,
    url: string,
    meta: string,
    content: any | null
}

const props = defineProps({
    'page': Object as PropType<Page>
})

const pageFormRef = ref<FormInstance>()
const pageForm: Page = reactive({
    id: null,
    name: '',
    title: '',
    url: '',
    meta: '',
    content: [],
    ...props.page
})

const rules = reactive<FormRules>({
    meta: [
        { required: true, message: "Vul een meta beschrijving in voor deze pagina", trigger: "blur"},
        { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" }
    ]
})

const pageComponent = defineAsyncComponent(() => 
    import(`./contentForm/${pageForm.name}Page.vue`)
)

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    await formEl.validate((valid, fields) => {      
        if (!valid)
            return false

        http.put('/paginas/' + pageForm.id, pageForm)
            .then(() => savedSuccesNotification())
            .catch(() => {return false})
    });
}

const addPerson = (type: string) => {
    const new_person = {
        'name': '',
        'image': '',
        'text': ''
    }

    if (type == 'mentors') {
        if (pageForm.content && pageForm.content.mentors) {
            pageForm.content.mentors.push(new_person)
        }
    } else if (type == 'studentassistents') {
        if (pageForm.content && pageForm.content.studentassistents) {
            pageForm.content.studentassistents.push(new_person)
        }
    }
}

const removePerson = (type: string, index: number) => {
    if (type == 'mentors') {
        if (pageForm.content && pageForm.content.mentors) {
            pageForm.content.mentors.splice(index, 1)
        }
    } else if (type == 'studentassistents') {
        if (pageForm.content && pageForm.content.studentassistents) {
            pageForm.content.studentassistents.splice(index, 1)
        }
    }
}

const updatePhoto = (type: string, index: number, file_url: string) => {
    if (type == 'mentors') {
        if (pageForm.content && pageForm.content.mentors) {
            pageForm.content.mentors[index].image = file_url
        }
    } else if (type == 'studentassistents') {
        if (pageForm.content && pageForm.content.studentassistents) {
            pageForm.content.studentassistents[index].image = file_url
        }
    }
}

function savedSuccesNotification() {
    ElNotification({
        title: 'Opgeslagen',
        message: 'Pagina succesvol opgeslagen!',
        type: 'success'
    });
}
</script>
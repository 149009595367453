<template>
    <Line :data="data" :options="options" />
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js'
import { Line } from 'vue-chartjs'

interface NewUsersData {
    labels: string[];
    data: number[];
}

const props = defineProps<{
    newUsers: NewUsersData;
}>();

const data = computed(() => {
    return {
        labels: props.newUsers.labels,
        datasets: [
            {
                label: 'Aantal nieuwe registraties',
                backgroundColor: '#2b3d51',
                data: props.newUsers.data
            }
        ]
    }
})

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const options: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false
};
</script>
<template>
    <div>
        <el-table :data="registrations" :border="true" style="width: 100%" v-if="event">
            <el-table-column type="expand" v-if="event.form">
                <template #default="props">
                    <div style="margin-left: 60px; margin-right: 12px;">
                        <h6>Formulier antwoorden:</h6>
                        <el-row>
                            <el-col :span="12" v-for="question in props.row.form_input">
                                <b>{{ question.label }} </b>
                                <template v-if="question.value != null">
                                    {{ (question.type != 'checkbox' ? question.value : question.value.join(', ')) }}
                                </template>
                                <template v-else>
                                    <i>Geen antwoord gegeven</i>
                                </template>
                            </el-col>
                        </el-row>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Naam" prop="user.name" />
            <el-table-column label="Betaald" prop="amount_to_pay" v-if="event.use_payment">
                <template #default="scope">
                    <span class="text-danger" v-if="Number(scope.row.sum_paid) === 0 && Number(scope.row.sum_paid) !== Number(scope.row.amount_to_pay)">
                        Niet betaald
                    </span>
                    <span class="text-success" v-if="Number(scope.row.sum_paid) === Number(scope.row.amount_to_pay)">
                        Betaald - &euro;{{ scope.row.sum_paid }}
                    </span>
                    <span class="text-warning" v-if="Number(scope.row.sum_paid) > Number(scope.row.amount_to_pay)">
                        Teveel betaald - &euro;{{ scope.row.sum_paid }} / &euro;{{ scope.row.amount_to_pay }}
                    </span>
                    <span class="text-warning" v-if="Number(scope.row.sum_paid) > 0 && Number(scope.row.sum_paid) < Number(scope.row.amount_to_pay)">
                        Gedeeltelijk betaald - &euro;{{ scope.row.sum_paid }} / &euro;{{ scope.row.amount_to_pay }}
                    </span>
                    <span class="text-primary" v-if="Number(scope.row.sum_refunded) < 0" style="display: block;">
                        Terugbetaald: &euro;{{ scope.row.sum_refunded }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="Acties">
                <template #default="scope">
                    <!-- <a class="btn btn-primary btn-sm" v-if="event.form_id != 0" @click="openDialog(reg)">Inschrijving aanpassen</a> -->
                    <a v-if="scope.row.user.phone" :href="'https://wa.me/' + fixWhatsappNumber(scope.row.user.phone)"
                        target="_blank" class="btn btn-success btn-sm btn-has-icon">
                        <span class="mdi mdi-whatsapp"></span>
                    </a>
                    <button v-if="scope.row.sum_paid > 0 && event.walletTransactionsOpen" @click="refundRegistration(scope.row.id, scope.row.sum_paid)"
                        class="btn btn-primary btn-sm btn-has-icon">
                        <span class="mdi mdi-credit-card-refund"></span>
                        <span class="btn-text">
                            Terugbetalen
                        </span>
                    </button>
                    <button @click="removeRegistration(scope.row.id)" class="btn btn-danger btn-sm btn-has-icon">
                        <span class="mdi mdi-delete"></span>
                        <span class="btn-text">
                            Verwijder inschrijving
                        </span>
                    </button>
                </template>
            </el-table-column>
        </el-table>

        <edit-registration v-model:editRegistrationDialogVisible="editRegistrationDialogVisible"
            :registration="selectedRegistration" v-if="1 == 2"></edit-registration>
    </div>
</template>



<script setup lang="ts">
import { PropType, h, ref } from 'vue'
import editRegistration from './editRegistration.vue'
import http from "../../libs/axios"
import moment from 'moment'
import { ElMessageBox, ElSwitch, ElInputNumber, ElCheckbox } from 'element-plus';
import { Event, Registration } from '../../types/generated'

const props = defineProps({
    'event': Object as PropType<Event>,
    'registrations': Array<Registration>
})

const editRegistrationDialogVisible = ref(false)
const selectedRegistration = ref({})

function removeRegistration(id: number) {
    ElMessageBox.confirm('Weet je zeker dat je deze registratie wilt verwijderen?')
        .then(() => {
            if (id) {
                http.delete('/agenda/registraties/' + id)
                    .then(() => {
                        window.location.reload();
                    })
                    .catch(() => { return false });
            }
        })
        .catch(() => { });
}

function refundRegistration(id: number, amount_paid: number = 0) {
    const useCustomAmount = ref(false);
    const refundAmount = ref(amount_paid);
    const deleteRegistration = ref(true);

    const handleCheckboxChange = (value: boolean) => {
        useCustomAmount.value = value;
        if (value) {
            deleteRegistration.value = false;
        }
    };

    const handleRefundAmountChange = (value: number) => {
        refundAmount.value = value;
    };

    ElMessageBox(
        {
            title: 'Terugbetaling',
            confirmButtonText: 'Bevestigen',
            cancelButtonText: 'Annuleren',
            customClass: '',
            showClose: false,
            showCancelButton: true,
            showConfirmButton: true,
            beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                    if (id) {
                        const refundData = {
                            refundAmount: useCustomAmount.value ? refundAmount.value : null,
                            deleteRegistration: deleteRegistration.value
                        };

                        http.post('/agenda/registraties/' + id + '/terugbetalen', refundData)
                            .then(() => {
                                window.location.reload();
                            })
                            .catch(() => {
                                return false;
                            });
                    }
                }
                done();
            },
            callback: (action: string) => {
                if (action === 'cancel') {
                    // Handle cancel action
                }
            },
            message: () => h('div', [
                h('p', 'Weet je zeker dat je deze registratie wilt terugbetalen?'),
                h('br'),
                h(ElSwitch, {
                    activeText: "Gedeeltelijk",
                    inactiveText: "Volledig",
                    modelValue: useCustomAmount.value,
                    'onUpdate:modelValue': handleCheckboxChange
                }, 'Volledig bedrag'),
                h('br'),
                useCustomAmount.value ? h(ElInputNumber, {
                    modelValue: refundAmount.value,
                    precision: 2,
                    step: 0.01,
                    'onUpdate:modelValue': handleRefundAmountChange,
                }, 'Terug te betalen bedrag') : null,
                h('br'),
                h(ElCheckbox, {
                    modelValue: deleteRegistration.value,
                    'onUpdate:modelValue': (value: boolean) => deleteRegistration.value = value
                }, 'Registratie verwijderen'),
            ])
        });
}

function openDialog(registration: Object) {
    selectedRegistration.value = registration;
    editRegistrationDialogVisible.value = true;
}

function formatDate(date: number) {
    return moment(date).format('DD/MM/Y');
}

function fixWhatsappNumber(number: string) {
    // Remove 06 from phonenumber
    let cut_off_number = number.replace(/^(06)/, "");

    // Remove dash from phonenumber
    cut_off_number = cut_off_number.replace("-", "");
    // Remove spaces
    cut_off_number = cut_off_number.replace(" ", "");


    // Add 316 to phonenumber
    cut_off_number = "316" + cut_off_number;

    return cut_off_number;
}
</script>
<template>
    <div class="option-box">
        <div class="edit-mode">
            <div class="row option">
                <div class="col-sm-4">
                    <el-form-item prop="id">
                        <el-input v-model="optionForm.id" disabled></el-input>
                    </el-form-item>

                    <el-form-item prop="option_name">
                        <el-input v-model="optionForm.name" placeholder="Vraag"></el-input>
                    </el-form-item>

                    <el-form-item prop="option_description">
                        <el-input v-model="optionForm.description" placeholder="Extra uitleg: is niet altijd nodig"></el-input>
                    </el-form-item>
                </div>

                <div class="col-sm-8">
                    <el-tag 
                        v-for="(value, index) in optionForm.values"
                        :key="index"
                        closable
                        :disable-transitions="false"
                        @close="handleClose(value)">
                        {{value.name}}
                    </el-tag>
                    <el-input
                        class="input-new-tag"
                        v-if="inputVisible"
                        v-model="inputValue"
                        ref="saveTagInputRef"
                        size="small"
                        @keyup.enter.native="handleInputConfirm"
                        @blur="handleInputConfirm"
                    >
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ Nieuwe Waarde</el-button>
                </div>
            </div>

            <div class="option-footer">
                <span class="delete" @click="removeOption()">
                    <i class="mdi mdi-trash-can"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { nextTick, ref, computed } from 'vue'
import { ElInput } from 'element-plus'

const props = defineProps(['option'])
const emit = defineEmits(['update:option', 'remove-option'])

const optionForm = computed({
    get() {
        return props.option
    },
    set(value) {
        emit('update:option', value)
    }
})

function removeOption() {
    emit('remove-option')
}

const inputValue = ref('')
const inputVisible = ref(false)
const saveTagInputRef = ref<InstanceType<typeof ElInput>>()

function handleClose(tag: string) {
    optionForm.value.values.splice(optionForm.value.values.indexOf(tag), 1);
}

function showInput() {
    inputVisible.value = true;
    nextTick(() => {
        saveTagInputRef.value!.input!.focus()
    })
}

function handleInputConfirm() {
    if (inputValue.value) {
        optionForm.value.values.push({id: null, product_option_id: null, name: inputValue.value});
    }

    inputVisible.value = false;
    inputValue.value = '';
}

</script>

<style lang="scss">
  .el-tag {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .button-new-tag {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: center;
  }
</style>
import { createApp, h } from 'vue'
import { convertDataSet } from './helper'

import AssignUsers from '../components/achievement/assignUsers.vue'

if (document.getElementById("assignAchievementUsers")) {
    const app1 = createApp({
        render() {
          return h(AssignUsers, convertDataSet('assignAchievementUsers'))
        }
    })
    
    app1.mount('#assignAchievementUsers')
}
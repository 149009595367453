import { createApp, h } from 'vue'
import { convertDataSet } from './helper'
import IndexTable from '../components/user/indexTable.vue'
import EditPermissions from '../components/user/editPermissions.vue'
import newUsersView from '../components/user/newUsersView.vue'

if (document.getElementById("indexUsersTable")) {
    const app1 = createApp({
        render() {
          return h(IndexTable, convertDataSet('indexUsersTable'))
        }
    })
    
    app1.mount('#indexUsersTable')
}

if (document.getElementById("editPermissions")) {
    const app2 = createApp({
        render() {
          return h(EditPermissions, convertDataSet('editPermissions') )
        }
    })

    app2.mount('#editPermissions')
}

if (document.getElementById("newUsers")) {
  const app2 = createApp({
      render() {
        return h(newUsersView, convertDataSet('newUsers') )
      }
  })

  app2.mount('#newUsers')
}
<template>
    <div>
        <el-table :data="registrations" :border="true" style="width: 100%">

            <!-- <el-table-column label="ID" prop="id" /> -->
            <el-table-column label="Activiteit" prop="event.name" />
            <el-table-column label="Naam" prop="user.name" />
            <el-table-column label="Betaald" prop="total_paid">
                <template #default="scope">
                    <span class="text-danger"
                        v-if="scope.row.total_paid == 0 && scope.row.total_paid != scope.row.amount_to_pay">Niet
                        betaald (&euro;{{ scope.row.amount_to_pay }})</span>
                    <span class="text-success" v-if="scope.row.total_paid == scope.row.amount_to_pay">
                        Betaald - &euro;{{ scope.row.total_paid }}
                    </span>
                    <span class="text-warning" v-if="scope.row.total_paid > scope.row.amount_to_pay">
                        Teveel betaald - &euro;{{ scope.row.total_paid }} / &euro;{{ scope.row.amount_to_pay }}
                    </span>
                    <span class="text-warning"
                        v-if="scope.row.total_paid > 0 && scope.row.total_paid < scope.row.amount_to_pay">
                        Gedeeltelijk betaald - &euro;{{ scope.row.total_paid }} / &euro;{{ scope.row.amount_to_pay }}
                    </span>
                   
                </template>
            </el-table-column>

            <el-table-column label="Acties">
                <template #default="scope">
                    <a v-if="scope.row.user.phone" :href="'https://wa.me/' + fixWhatsappNumber(scope.row.user.phone)"
                        target="_blank" class="btn btn-success btn-sm"><span class="mdi mdi-whatsapp"></span></a>
                    <button @click="removeRegistration(scope.row.id)" class="btn btn-danger btn-sm">Verwijder
                        inschrijving</button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import http from "../../libs/axios"

const props = defineProps({
    'registrations': Array<[]>
})

function removeRegistration(id: number) {
    ElMessageBox.confirm('Weet je zeker dat je deze registratie wilt verwijderen?')
        .then(() => {
            if (id) {
                http.delete('/agenda/registraties/' + id)
                    .then(() => {
                        window.location.reload();
                    })
                    .catch(() => { return false });
            }
        })
        .catch(() => { });
}

function fixWhatsappNumber(number: string) {
    // Remove 06 from phonenumber
    let cut_off_number = number.replace(/^(06)/, "");

    // Remove dash from phonenumber
    cut_off_number = cut_off_number.replace("-", "");
    // Remove spaces
    cut_off_number = cut_off_number.replace(" ", "");


    // Add 316 to phonenumber
    cut_off_number = "316" + cut_off_number;

    return cut_off_number;
}
</script>
<template>
    <el-form :model="achievementForm" label-width="120px" label-position="top" @submit.prevent="submitForm()">
        <input type="hidden" v-model="achievementForm.id" disabled readonly>
        
        <div class="p-20">
            <div class="row">
                <div class="col-md-12">
                    <el-transfer
                        filterable
                        :filter-method="filterMethod"
                        filter-placeholder="Zoek naam"
                        v-model="selectedUsers"
                        :data="users"
                        :props="{
                            key: 'id',
                            label: 'name'
                        }">
                    </el-transfer>
                </div>
            </div>
        </div>

        <el-form-item>
            <el-button type="primary" class="btn btn-primary waves-effect waves-light" @click="submitForm()">
                Opslaan
            </el-button>
        </el-form-item>
    </el-form>
</template>
 
<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue'
import type { PropType } from 'vue'
import http from "../../libs/axios"

import { Achievement, AchievementUser } from '../../types/achievement'

const selectedUsers = ref()

const props = defineProps({
    'achievement': Object as PropType<Achievement>,
    'users': Array<[]>,
})

const achievementForm: Achievement = reactive({
    id: 0,
    users: [],
    ...props.achievement
})

onMounted(() => {
    if (props.achievement && props.achievement.id) {
        selectedUsers.value = props.achievement.users.map(user => user.id)
    }
})

const submitForm = async () => {
    await http.put('/achievements/' + achievementForm.id, {'users': selectedUsers.value})
        .then(() => savedSuccesNotification())
        .catch(() => {return false})
}

function filterMethod(query : string, item : AchievementUser) {
    return item.name.toLowerCase().replace(/ /g,'').indexOf(query.toLowerCase().replace(/ /g,'')) > -1;
}

function savedSuccesNotification() {
    ElNotification({
        title: 'Opgeslagen',
        message: 'Achievement succesvol opgeslagen!',
        type: 'success'
    });
}

</script>
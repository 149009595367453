function convertDataSet(elementId) {
    let props = {}
    
    if (document.getElementById(elementId)) {
        let dataset = Object.assign({}, document.getElementById(elementId).dataset);

        Object.keys(dataset).forEach(key => {
            if (IsJsonString(dataset[key])) {
                props[key] = JSON.parse(dataset[key]);
            } else {
                let value = dataset[key];

                // Convert int in string to integer
                if (value == parseInt(value).toString()) {
                    value = parseInt(value);
                }
                props[key] = value;
            }
        });
    }

    return props
}

function IsJsonString(str) {
    try {
      var json = JSON.parse(str);
      return (typeof json === 'object');
    } catch (e) {
      return false;
    }
}

export { convertDataSet };
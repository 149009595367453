<template>
    <div>
        <div v-if="saving">Aan het opslaan...</div>
        <el-tree
            :data="folders"
            show-checkbox
            node-key="id"
            ref="tree"
            default-expand-all
            :default-checked-keys="activeTree"
            :expand-on-click-node="false"
            :props="defaultProps"
            @check-change="updateNodeStatus">
            <template #default="{ node, data }">
                <span class="custom-tree-node">
                    <span>
                        <span>{{ node.label }}</span>
                        <span v-if="!data.children" style="font-style: italic;"> ({{ data.category.name }})</span>
                    </span>

                    <span>
                        <a :href="'/fotos/' + data.id + '/bewerken'"> Bewerken </a>
                        <!-- <a style="margin-left: 8px" @click="remove(node, data)"> Delete </a> -->
                    </span>
                </span>
            </template>
        </el-tree>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import type { PropType } from 'vue'
import type Node from 'element-plus/es/components/tree/src/model/node'
import { TreeKey } from 'element-plus/es/components/tree/src/tree.type'
import http from "../../libs/axios"

interface Tree {
  id: number
  label: string
  category: {
    id: number,
    name: string
  },
  children?: Array<Tree[]>
}

const props = defineProps({
    'activeTree': Array<TreeKey>,
    'folders' : Array as PropType<Tree[]>
})

const saving = ref(false)
const defaultProps = {
  children: 'children',
  label: 'name'
}

const activateFolder = async (folderId : number, status : boolean) => {
    saving.value = true

    http.put('/fotos/' + folderId + '/status',
        { status: status }
    )
        .then(() => 
            ElMessage({
                message: "Status van map(pen) succesvol bijgewerkt.",
                grouping: true,
                type: 'success',
            })    
        )
        .catch(() => {return false})
        .finally(() => {saving.value = false})
}

const updateNodeStatus = async (node : Node, checked : boolean) => {
    let isChecked = false;

    if ('children' in node) {
        isChecked = true;
    } else {
        isChecked = checked;
    }

    await activateFolder(node.id, isChecked);
}
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
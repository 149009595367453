<template>
    <el-form ref="permissionsFormRef" label-width="120px" label-position="top" @submit.prevent="submitForm()">
        <h5 class="header-title mt-0">Toestemmingen</h5>
        <div class="p-20">
            <el-checkbox-group v-model="editPermissions">
                <div class="row">
                    <div v-for="permission in permissions" :key="permission.id">
                        <el-checkbox :label="permission.id">{{permission.name}}</el-checkbox>
                    </div>
                </div>
            </el-checkbox-group>
        </div>

        <el-form-item>
            <el-button type="primary" class="btn btn-primary waves-effect waves-light" @click="submitForm()">
                Opslaan
            </el-button>
        </el-form-item>
    </el-form>
</template>
 
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { ElNotification } from 'element-plus'
import type { FormInstance } from 'element-plus'
import http from "../../libs/axios"

interface permission {
    id: number,
    name: string,
    guard_name: string
}

interface selectedPermissions {
    id: number,
    name: string
}

const props = defineProps({
    'permissions': Array<permission>,
    'selectedPermissions': Array<selectedPermissions>,
    'userId': Number
});

const permissionsFormRef = ref<FormInstance>()
let editPermissions = ref()

onMounted(() => {
    if (props.selectedPermissions)
        editPermissions.value = props.selectedPermissions.map(ability => ability.id)
})

async function submitForm() {
    if (props.userId) {
        await http.put('/leden/'  + props.userId + '/toestemmingen', {'permissions': editPermissions.value})
            .then(() => savedSuccesNotification())
            .catch(() => {return false})
    }
}

function savedSuccesNotification() {
    ElNotification({
        title: 'Opgeslagen',
        message: 'Toestemmingen succesvol opgeslagen!',
        type: 'success'
    });
}
</script>
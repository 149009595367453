<template>
    <table class="table table-bordered table-striped" v-if="product">
        <thead>
            <tr>
                <th>Variant ID</th>
                <th v-for="option in product.options" v-bind:key="'heading-' + option.id">{{option.name}}</th>
                <th>Voorraad</th>
                <th>Acties</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(row, index) in stock" v-bind:key="'stock-' + row.id">
                <td>{{row.variant_slug}}</td>
                <td v-for="(value, key) in getValueNames(row.variant_slug)" v-bind:key="'value-' + key">{{value}}</td>
                <td><el-input-number
                        :name="'quantity-' + row.id"
                        v-model.number="row.quantity"
                        :min="0"
                        size="small"
                    ></el-input-number></td>
                <td>
                    <el-button type="success" @click="updateStockItem(row.id, row.quantity)" :disabled="savedStock[row.id] == row.quantity"><i class="mdi mdi-content-save"></i></el-button>
                    <el-button 
                        type="danger"
                        title="Aantal moet 0 zijn om te kunnen verwijderen."
                        :disabled="!(row.quantity == 0 && savedStock[row.id] == row.quantity)"
                        @click="deleteStockItem(row.id)"
                    ><i class="mdi mdi-trash-can"></i></el-button>

                    
                </td>
            </tr>
            <tr v-if="Object.keys(stock).length == 0 || product.options.length > 0">
                <td>{{ newVariantSlug }}</td>
                <td v-for="option in product.options" v-bind:key="'column-' + option.id">
                    <el-select v-model="new_item.selection['select-' + option.id]">
                        <el-option 
                            v-for="value in option.values"
                            v-bind:key="'value-' + value.id"
                            :value="value.id"
                            :label="value.name"
                        >
                        </el-option>
                    </el-select>
                </td>
                <td>
                    <el-input-number
                        v-model.number="new_item.quantity"
                        :min="1"
                        :steps="1"
                    ></el-input-number>
                </td>
                <td>
                    <el-button type="success" @click="addStockItem()"><i class="mdi mdi-check"></i></el-button>
                </td>
            </tr>
        </tbody>
    </table>
</template>
 
<script setup lang="ts">
import { ref, reactive, computed } from 'vue'
import type { Ref } from 'vue';
import http from "../../libs/axios"

interface Stock {
    id: number,
    product_id: number,
    variant_slug: string,
    quantity: number
}

const props = defineProps({
    'product': Object
})
const stock: Ref<Array<Stock>> = ref([])
const new_item = reactive({
    selection: {},
    quantity: 0
})
const valuesById = ref({})
const definedVariants: Ref<Array<string>> = ref([])
const savedStock = ref({})

if (props.product && props.product.stock) {
    stock.value = { ...props.product.stock }

    props.product.options.forEach(option => {
        option.values.forEach(val => {
            valuesById.value[val.id] = val.name
        })
    })

    Object.values(stock.value).forEach(val => {
        definedVariants.value.push(val.variant_slug)

        savedStock.value[val.id] = val.quantity
    })
}

const newVariantSortedIds = computed(() => {
    let sortedValues: string[] = []

    if (Object.keys(new_item.selection).length > 0) {
        let o = new_item.selection
        let sortedObj = Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {})
        
        sortedValues = Object.values(sortedObj)
    }

    return sortedValues
})

const newVariantSlug = computed(() => {
    let slug: string = 'Komt vanzelf'

    if (newVariantSortedIds.value.length > 0) {
        slug = newVariantSortedIds.value.join('_')
    }
    
    return slug
})

function getValueNames(variant_slug: string | null) {
    if (!variant_slug)
        return []
    
    let values = variant_slug.split("_")

    values.forEach((val, key) => {
        values[key] = valuesById.value[val]
    });

    return values
}

const addStockItem = async () => {
    if (!props.product)
        return false
    
    if (newVariantSortedIds.value.length != props.product.options.length) {
        errorNotification('Selecteer voor alle opties een waarde.')
        return false
    }

    if (definedVariants.value.includes(newVariantSlug.value)) {
        errorNotification('Deze variant bestaat al. Verander de voorraad van de bestaande variant.')
        return false
    }

    await http.post('/producten/' + props.product.id + '/voorraad', new_item)
        .then(() => {
            savedSuccesNotification('Nieuw voorraad item toegevoegd.')
            window.location.reload()
        })
        .catch(() => {return false})
}

const updateStockItem = async (itemId: number, itemQuantity: number) => {
    await http.put('/producten/voorraad/' + itemId, {
        quantity: itemQuantity
    })
        .then(() => {
            savedStock.value[itemId] = itemQuantity;
            savedSuccesNotification('Aantal voorraad bijgewerkt.')
        })
        .catch(() => {return false})
}

const deleteStockItem = async (itemId: number) => {
    await http.delete('/producten/voorraad/' + itemId)
        .then(() => {
            savedSuccesNotification('Voorraaditem verwijderd.')
            window.location.reload()
        })
        .catch(() => {return false})
}

function savedSuccesNotification(message: string) {
    ElMessage({
        title: 'Opgeslagen',
        message: message,
        type: 'success'
    })
}
function errorNotification(message: string) {
    ElMessage({
        title: 'Foutje',
        message: message,
        type: 'error'
    })
}
</script>
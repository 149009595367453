<template>
    <el-form :model="productForm" :rules="rules" ref="productFormRef" label-width="120px" label-position="top" @submit.prevent="submitForm(productFormRef)">
        <div class="card-box">
            <h3 class="header-title mt-0">Gegevens {{ (productForm && productForm.id ? productForm.name : 'product') }}</h3>
            <p class="text-muted font-13 mb-2">
                Alle velden met een <span class="required">*</span> zijn verplicht
            </p>

            <input type="hidden" v-model="productForm.id" disabled readonly>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Naam" prop="name">
                        <el-input v-model="productForm.name" placeholder="Bijv: Vox Trui 2000"></el-input>
                    </el-form-item>
                </div>

                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Beschrijving" prop="description">
                        <editor 
                            :api-key="tinymceApiKey"
                            cloud-channel="6"
                            :init="{
                            plugins: 'lists',
                            menubar: false,
                            toolbar: [
                                { name: 'history', items: [ 'undo', 'redo' ] },
                                { name: 'formatting', items: [ 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'underline' ] },
                                { name: 'alignment', items: [ 'alignleft', 'aligncenter', 'alignright', 'alignjustify' ] },
                                { name: 'indentation', items: [ 'outdent', 'indent' ] },
                                { name: 'lists', items: ['bullist', 'numlist', ]},
                                { name: 'other', items: ['removeformat ']}
                            ]
                            }"
                            v-model="productForm.description">
                        </editor>
                    </el-form-item>
                </div>
            </div>

            <h5 class="header-title mt-4">Betaling</h5>
            <div class="row">
                <div class="col-md-6">
                    <el-form-item label="Kosten" prop="price">
                        <el-input type="number" v-model.number="productForm.price" step="0.5"></el-input>
                    </el-form-item>
                </div>

                <div class="col-md-6">
                    <el-form-item label="Product afbeelding" prop="thumbnail">
                        <el-input v-model="productForm.thumbnail" placeholder="Kies een thumbnail" readonly>
                            <template #append>
                                <a class="btn" @click="openFileManager()" style="color: black;">Kies afbeelding</a>
                            </template>
                        </el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <el-form-item label="Product zichtbaar tot" prop="active_until">    
                                <el-date-picker 
                                    v-model="activeUntilDate"
                                    type="datetime"
                                    format="DD/MM/YYYY HH:mm"
                                    value-format="x"
                                    placeholder="Kies datum"
                                >
                                </el-date-picker>
                                <p class="text-muted"><b>Bedenk:</b> 00:00 is aan het begin van de dag, dus doe de datum +1!</p>
                            </el-form-item>
                        </div>
                        <div class="col-4">
                            <el-button type="primary" @click="setProductAlwaysActive">Zet product op altijd actief</el-button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <el-form-item label="Commissie" prop="committee_id">
                        <el-select v-model="productForm.committee_id" placeholder="Selecteer een commissie">
                            <el-option
                                v-for="committee in committees"
                                :label="committee.name"
                                :value="committee.id"
                                :key="committee.id"
                            ></el-option>
                        </el-select>
                        <p class="text-muted">Selecteer een optie om notificaties te krijgen van aankopen van dit product.</p>
                    </el-form-item>
                </div>
            </div>
        </div>

        <h5 class="header-title mt-0">Product opties</h5>
        
        <div class="options" v-if="productForm.options">
            <div v-for="(option, index) in productForm.options" :key="index">
                <option-component
                    v-model:option="productForm.options[index]"
                    @remove-option="removeOption(index)"
                ></option-component>
            </div>

            <el-button type="success" class="mb-3" @click="addOption()">
                Optie toevoegen
            </el-button>
        </div>

        <el-form-item>
            <el-button type="primary" @click="submitForm(productFormRef)">
                Opslaan
            </el-button>
        </el-form-item>
    </el-form>
</template>
 
<script setup lang="ts">
import { ref, reactive, computed } from 'vue'
import type { PropType } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import Editor from '@tinymce/tinymce-vue'
import Flmngr from "flmngr"
import http from "../../libs/axios"
import { Committee } from '../../types/committee'

import optionComponent from './option.vue'

interface Product {
    id: number | null,
    name: string,
    description: string,
    thumbnail: string,
    price: number,
    active_until: number,
    committee_id: number | null,
    options: Array<Option>
}

interface Option {
    id: number | null,
    product_id: number | null,
    name: string,
    description: string,
    values: Array<Value>
}

interface Value {
    id: number | null,
    product_option_id: number | null,
    name: string
}

const props = defineProps({
    'product': Object as PropType<Product>,
    'committees': Array<Committee>
})

const flmngrApiKey = import.meta.env.VITE_FLMNGR_API_KEY
const tinymceApiKey = import.meta.env.VITE_TINYMCE_API_KEY
const productFormRef = ref<FormInstance>()
const productForm: Product = reactive({
    id: null,
    name: '',
    description: '',
    thumbnail: '',
    price: 0,
    active_until: 0,
    committee_id: null,
    options: [{
        id: null,
        product_id: null,
        name: '',
        description: '',
        values: []
    }],
    ...props.product
})

const rules = reactive<FormRules>({
    name: [
        { required: true, message: "Vul de naam in van het product", trigger: "blur"},
        { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" }
    ],
    description: [
        { required: true, message: "Vul een beschrijving in voor het product", trigger: "blur"}
    ],
    price: [
        { required: true, message: "Vul een prijs in", trigger: "blur"},
        { type: "number", min: 0, message: "Moet een positief getal zijn", trigger: "blur" }
    ],
    thumbnail: [
        { required: true, message: "Voeg een plaatje toe voor het product", trigger: "blur"}
    ],
    active_until: [
        { type: "number", required: true, message: "Kies een datum", trigger: "change" }
    ],
    committee_id: [
        { type: "number", required: true, message: "Selecteer een commissie", trigger: "blur" }
    ],
})

Flmngr.load({
    apiKey: flmngrApiKey, // default free key
})

const activeUntilDate = computed({
    get() {
        return productForm.active_until * 1000;
    },
    set( newValue ) {
        productForm.active_until = newValue / 1000;
    }
});

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    await formEl.validate((valid, fields) => {
        if (valid) {
            if (productForm.id === null) {
                http.post('/producten', productForm)
                    .then(response => {
                        savedSuccesNotification()

                        // Set current url to edit
                        let newUrl = window.location.origin + "/producten/" + response.id + "/bewerken"
                        window.location.assign(newUrl)
                        window.open(newUrl, "_top")
                    })
                    .catch(() => {return false})
            } else {
                http.put('/producten/' + productForm.id, productForm)
                    .then(() => savedSuccesNotification())
                    .catch(() => {return false})
            }
        } else {
            return false
        }
    });
}

function savedSuccesNotification() {
    ElMessage({
        title: 'Opgeslagen',
        message: 'Product succesvol opgeslagen!',
        type: 'success'
    })
}

function openFileManager() {
    Flmngr.open({
        urlFileManager: 'https://admin.voxvivarotterdam.nl/producten/bestanden', // server
        urlFiles: '/storage/products', // file storage
        isMultiple: false,
        showMaximizeButton: false,
        acceptExtensions: ["png", "jpeg", "jpg"],
        onFinish: (files) => {
            productForm.thumbnail = files[0].url;
        }
    })
}
        
function addOption() {
    if (!productForm.options) {
        productForm.options = [];
    }

    productForm.options.push({
        id: null,
        product_id: null,
        name: 'Optie',
        description: '',
        values: []
    });
}
function removeOption (index: number) {
    ElMessageBox.confirm('Weet je zeker dat je deze optie wilt verwijderen?')
        .then(() => {
            productForm.options.splice(index, 1);
        })
        .catch(() => {});
}

function setProductAlwaysActive() {
    productForm.active_until = 4102444800;
}
</script>


<style lang="scss">
.option-box {
    background-color: #fff;
    padding: 1.25rem 10px 0;
    box-shadow: 0px 0px 35px 0px rgb(73 80 87 / 15%);
    margin-bottom: 24px;
    border-radius: 0.25rem;

    .option {
        padding: 0 1rem;
    }

    .option-footer {
        margin: 1.75rem 1rem 0;
        padding: 10px 0;
        border-top: 1px solid #DCDFE6;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > span {
            border-right: 1px solid #DCDFE6;
            padding: 0 1.5rem;

            &.delete {
                font-size: 1.3rem;

                &:hover {
                    cursor: pointer;
                    color: #F56C6C;
                }
            }

            &:last-child {
                border-right: none;
                padding-right: 0;
            }
        }
    }
}
</style>
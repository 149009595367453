import { createApp, h } from 'vue'
import { convertDataSet } from './helper'

import CreateForm from '../components/form/createForm.vue'

if (document.getElementById("createForm")) {
    const app1 = createApp({
        render() {
          return h(CreateForm, convertDataSet('createForm'))
        }
    })
    
    app1.mount('#createForm')
}
<template>
    <el-form :model="committeeForm" :rules="rules" ref="committeeFormRef" label-width="120px" label-position="top" @submit.prevent="submitForm(committeeFormRef)">
        <input type="hidden" v-model="committeeForm.id" disabled readonly>
        <div class="p-20">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Naam" prop="name">
                        <el-input v-model="committeeForm.name"></el-input>
                    </el-form-item>
                </div>

                <div class="col-md-6 col-sm-12">
                    <el-form-item label="E-mailadres" prop="email">
                        <el-input v-model="committeeForm.email" type="email"></el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <el-form-item label="Beschrijving" prop="description">
                        <editor 
                            :api-key="tinymceApiKey"
                            cloud-channel="6"
                            :init="{
                                menubar: false
                            }"
                            v-model="committeeForm.description">
                        </editor>
                    </el-form-item>
                </div>
            </div>
        </div>
        
        <div class="p-20">
            <div class="row">
                <div class="col-md-12">
                    <el-transfer
                        filterable
                        :filter-method="filterMethod"
                        filter-placeholder="Zoek naam"
                        v-model="selectedMembers"
                        :data="users"
                        :props="{
                            key: 'id',
                            label: 'name'
                        }">
                    </el-transfer>
                </div>
            </div>
        </div>

        <el-form-item>
            <el-button type="primary" class="btn btn-primary waves-effect waves-light" @click="submitForm(committeeFormRef)">
                Opslaan
            </el-button>
        </el-form-item>
    </el-form>
</template>
 
<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue'
import type { PropType } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import Editor from '@tinymce/tinymce-vue'
import http from "../../libs/axios"

import { Committee, CommitteeMember } from '../../types/committee'

const selectedMembers = ref()

const props = defineProps({
    'committee': Object as PropType<Committee>,
    'users': Array<[]>,
})

const tinymceApiKey = import.meta.env.VITE_TINYMCE_API_KEY
const committeeFormRef = ref<FormInstance>()
const committeeForm: Committee = reactive({
    id: null,
    name: '',
    email: '',
    description: '',
    active_members: [],
    ...props.committee
})

const rules = reactive<FormRules>({
    name: [
        { required: true, message: "Vul de naam in van de commissie", trigger: "blur"},
        { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" }
    ],
    email: [
        { required: true, message: "Vul het e-mailadres van de commissie in", trigger: "blur"},
        { type: 'email', message: "Vul een correct e-mailadres in", trigger: "blur" }
    ],
    description: [
        { required: true, message: "Vul een beschrijving in van de commissie", trigger: "blur"}
    ]
})

onMounted(() => {
    if (props.committee && props.committee.id) {
        selectedMembers.value = props.committee.active_members.map(member => member.user_id)
    }
})

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    await formEl.validate((valid, fields) => {
        if (valid) {
            if (committeeForm.id === null) {
                http.post('/commissies', {'form': committeeForm, 'members': selectedMembers.value})
                    .then((response) => {
                        savedSuccesNotification();

                        // Set current url to edit
                        let newUrl = window.location.origin + "/commissies/" + response.id + "/bewerken";
                        history.replaceState({}, '', newUrl);

                        committeeForm.id = response.id;
                    })
                    .catch(() => {return false})
            } else {
                http.put('/commissies/' + committeeForm.id, {'form': committeeForm, 'members': selectedMembers.value})
                    .then(() => savedSuccesNotification())
                    .catch(() => {return false})
            }
        } else {
            return false;
        }
    });
}

function filterMethod(query : string, item : CommitteeMember) {
    return item.name.toLowerCase().replace(/ /g,'').indexOf(query.toLowerCase().replace(/ /g,'')) > -1;
}

function savedSuccesNotification() {
    ElNotification({
        title: 'Opgeslagen',
        message: 'Commissie succesvol opgeslagen!',
        type: 'success'
    });
}

</script>
<template>
    <el-form :model="eventForm" :rules="rules" ref="eventFormRef" label-width="120px" label-position="top" @submit.prevent="submitForm(eventFormRef)">
        <input type="hidden" v-model="eventForm.id" disabled readonly>
        <div class="p-20">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Naam" prop="name">
                        <el-input v-model="eventForm.name" placeholder="Bijv: Thema avond 1"></el-input>
                    </el-form-item>
                </div>

                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Ondertitel" prop="extra_name">
                        <el-input v-model="eventForm.extra_name" placeholder="Bijv: Avond over de bijbel"></el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Beschrijving" prop="description">
                        <editor 
                            :api-key="tinymceApiKey"
                            cloud-channel="6"
                            :init="{
                                plugins: 'lists link',
                                menubar: false,
                                toolbar: [
                                    { name: 'history', items: [ 'undo', 'redo' ] },
                                    { name: 'styles', items: [ 'styles' ] },
                                    { name: 'formatting', items: [ 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'underline' ] },
                                    { name: 'alignment', items: [ 'alignleft', 'aligncenter', 'alignright', 'alignjustify' ] },
                                    { name: 'indentation', items: [ 'outdent', 'indent' ] },
                                    { name: 'lists', items: ['bullist', 'numlist', ]},
                                    { name: 'other', items: ['link', 'hr', 'removeformat', 'html']}
                                ]
                            }"
                            v-model="eventForm.description">
                        </editor>
                    </el-form-item>
                </div>

                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Categorie" prop="category_id">
                        <el-select v-model="eventForm.category_id" placeholder="Selecteer een categorie">
                            <el-option
                                v-for="category in categories"
                                :label="category.name"
                                :value="category.id"
                                :key="category.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="Foto's categorie" prop="photo_category_id">
                        <el-select v-model="eventForm.photo_category_id" placeholder="Selecteer een foto categorie" clearable>
                            <el-option
                                v-for="photo_category in photoCategories"
                                :label="photo_category.name"
                                :value="photo_category.id" 
                                :key="photo_category.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Banner afbeelding in app" prop="banner_image">
                        <el-input v-model="eventForm.banner_image" placeholder="Mag leeg zijn" readonly>
                            <template #append>
                                <a class="btn" @click="openFileManager('images')" style="color: black;">Kies afbeelding</a>
                            </template>
                        </el-input>
                        <p class="text-muted">Laat dit veld leeg om de standaard afbeelding te gebruiken</p>
                    </el-form-item>
                </div>
                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Bestand toevoegen aan beschrijving" prop="file_description">
                        <el-input v-model="eventForm.file_description" placeholder="Mag leeg zijn" readonly>
                            <template #append>
                                <a class="btn" @click="openFileManager('files')" style="color: black;">Kies bestand</a>
                            </template>
                        </el-input>
                        <p class="text-muted">Laat dit veld leeg om geen bestand toe te voegen</p>
                    </el-form-item>
                </div>
            </div>
        </div>

        <h5 class="header-title mt-0">Datum en tijd</h5>

        <div class="p-20">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Wanneer begint het" prop="start_event">
                        <el-date-picker 
                            v-model="startEventDate"
                            type="datetime"
                            format="DD/MM/YYYY HH:mm"
                            value-format="x"
                            :default-time="defaultStartDate"
                            placeholder="Kies datum en tijd"
                            >
                        </el-date-picker>
                    </el-form-item>
                </div>

                <div class="col-md-6 col-sm-12">
                    <el-form-item label="Wanneer is het afgelopen" prop="end_event">
                        <el-date-picker 
                            v-model="endEventDate"
                            type="datetime"
                            format="DD/MM/YYYY HH:mm"
                            value-format="x"
                            :default-time="defaultEndDate"
                            placeholder="Kies datum en tijd"
                            >
                        </el-date-picker>
                        <p class="text-muted">Leeg laten indien n.v.t.</p>
                    </el-form-item>
                </div>
            </div>
        </div>

        <h5 class="header-title mt-0">Locatie</h5>

        <div class="p-20">
            <div class="row">
                <div class="col-sm-6 col-12">
                    <el-form-item prop="location_known">
                        <el-switch
                            v-model="eventForm.location_known"
                            active-text="Locatie bekend"
                            inactive-text="Locatie onbekend"
                            @change="changeLocationKnown($event)">
                        </el-switch>
                    </el-form-item>
                </div>
            </div>

            <div id="location-container" v-if="eventForm.location_known">
                <div class="row">
                    <div class="col-md-12">
                        <div class="pac-card" id="pac-card">
                            <div>
                                <div id="title">
                                    Zoek adres
                                </div>
                            </div>
                            <div id="pac-container">
                                <input id="pac-input" type="text" placeholder="Vul een locatie in" v-model="location_search">
                            </div>
                        </div>
                        <div id="map" class="mb-3"></div>
                        <div id="infowindow-content">
                            <img id="place-icon" alt="Plaatje" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==">
                            <span id="place-name" class="title"></span><br>
                            <span id="place-address"></span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <el-form-item label="Naam locatie" prop="location">
                            <el-input v-model="eventForm.location" placeholder="Bijv: Sionkerk"></el-input>
                        </el-form-item>
                    </div>

                    <div class="col-md-3">
                        <el-form-item label="Adres" prop="address">
                            <el-input v-model="eventForm.address" placeholder="Vul een geldig adres in" readonly disabled></el-input>
                        </el-form-item>
                    </div>

                    <div class="col-md-3">
                        <el-form-item label="Stad" prop="city">
                            <el-input v-model="eventForm.city" placeholder="Vul een geldig adres in" readonly disabled></el-input>
                        </el-form-item>
                    </div>

                    <input type="hidden" name="lat" class="form-control" id="lat" readonly required v-model="eventForm.lat">
                    <input type="hidden" name="lng" class="form-control" id="lng" readonly required v-model="eventForm.lng">
                </div>
            </div>
        </div>

        <h5 class="header-title mt-0">Registratie</h5>

        <div class="p-20">
            <div class="row mb-4">
                <div class="col-sm-6 col-12">
                    <div class="form-group">
                        <el-switch
                            v-model="eventForm.register"
                            active-text="Met registratie"
                            inactive-text="Zonder registratie">
                        </el-switch>
                    </div>
                </div>

                <div class="col-sm-6 col-12 register_open" v-if="eventForm.register">
                    <div class="form-group">
                        <el-switch
                            v-model="eventForm.open_guests"
                            active-text="Registratie open voor gasten"
                            inactive-text="Registratie gesloten voor gasten">
                        </el-switch>
                    </div>
                </div>
            </div>

            <div v-if="eventForm.register">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <el-form-item label="Wanneer gaat de registratie open" prop="register_open">
                            <el-date-picker 
                                v-model="openRegisterDate"
                                type="datetime"
                                format="DD/MM/YYYY HH:mm"
                                value-format="x"
                                :default-time="defaultRegisterDate"
                                placeholder="Kies datum en tijd"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <el-form-item label="Wanneer sluit de registratie" prop="register_closed">
                            <el-date-picker 
                                v-model="closeRegisterDate"
                                type="datetime"
                                format="DD/MM/YYYY HH:mm"
                                value-format="x"
                                :default-time="defaultCloseRegisterDate"
                                placeholder="Kies datum en tijd"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3 col-sm-6" v-if="!eventForm.open_guests">
                        <el-form-item label="Kosten" prop="costs">
                            <el-input type="number" v-model.number="eventForm.costs" step="0.5"></el-input>
                            <p class="text-muted">Bij meerdere prijzen, vul de hoogste prijs in, kies ja bij het volgende veld en maak een formulier om te kiezen.</p>
                        </el-form-item>
                    </div>

                    <div class="col-md-3 col-sm-6" v-if="!eventForm.open_guests">
                        <el-form-item label="Meerdere prijzen" prop="adaptive_price">
                            <el-select v-model="eventForm.adaptive_price">
                                <el-option label="Nee" :value="false"></el-option>
                                <el-option label="Ja" :value="true"></el-option>
                            </el-select>
                            <p class="text-muted">Kies ook een formulier met een veld van type 'Radio prijs'</p>
                        </el-form-item>
                    </div>

                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                        <el-form-item label="Maximum inschrijvingen" prop="max_registrations">
                            <el-input type="number" v-model.number="eventForm.max_registrations"></el-input>
                            <p class="text-muted">Vul 0 in als er geen maximum is</p>
                        </el-form-item>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="!eventForm.open_guests">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <el-form-item label="Keer uit op rekening van" prop="use_payment">
                                <el-select v-model="eventForm.use_payment">
                                    <el-option label="Geen rekening / N.V.T." :value="0"></el-option>
                                    <el-option label="ActCie rekening" :value="1"></el-option>
                                    <el-option label="Algemene Vox Viva rekening" :value="2"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12 form">
                        <div class="form-group">
                            <el-form-item label="Formulier" prop="form_id">
                                <el-select v-model="eventForm.form_id" :default-first-option="true">
                                    <el-option label="Geen formulier" :value="0"></el-option>
                                    <el-option 
                                        v-for="form in formsSelect"
                                        :label="form.name"
                                        :value="form.id" 
                                        :key="form.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="errors">
            <span v-for="(error, key) in errors" :key="key">{{ error }}</span>
        </div>

        <el-form-item>
            <el-button type="primary" class="btn btn-primary waves-effect waves-light" @click="submitForm(eventFormRef)">
                Opslaan
            </el-button>
        </el-form-item>
    </el-form>
</template>
 
<script setup lang="ts">
import { computed, ref, reactive } from 'vue'
import type { PropType } from 'vue'
import { ElNotification } from 'element-plus'
import Editor from '@tinymce/tinymce-vue'
import { Loader } from "@googlemaps/js-api-loader"
import Flmngr from "flmngr"
import type { FormInstance, FormRules } from 'element-plus'
import http from "../../libs/axios"

interface Event {
    id: number | null,
    name: string,
    extra_name: string,
    description: string,
    category_id: number,
    photo_category_id: number | null,
    banner_image?: string,
    file_description?: string,
    start_event: number,
    end_event: number,
    location_known: boolean,
    location?: string,
    address?: string,
    city?: string,
    lat: number | null,
    lng: number | null,
    register: boolean,
    open_guests: boolean,
    register_open: number,
    register_closed: number,
    costs?: number,
    adaptive_price: boolean,
    max_registrations: number,
    use_payment: number,
    form_id: number
}

interface Category {
    id: number,
    name: string
}

interface PhotoCategory {
    id: number,
    name: string
}

const props = defineProps({
    'event': Object as PropType<Event>,
    'categories': Array<Category>,
    'forms': Array<[]>,
    'photoCategories': Array<PhotoCategory>
})

const flmngrApiKey = import.meta.env.VITE_FLMNGR_API_KEY
const tinymceApiKey = import.meta.env.VITE_TINYMCE_API_KEY
const defaultStartDate = new Date(2000, 1, 1, 19, 30, 0);
const defaultEndDate = new Date(2000, 1, 1, 22, 30, 0);
const defaultRegisterDate = new Date();
const defaultCloseRegisterDate = new Date();
defaultCloseRegisterDate.setHours(22, 0, 0)

const eventFormRef = ref<FormInstance>()
const eventForm: Event = reactive({
    id: null,
    name: '',
    extra_name: '',
    description: '',
    category_id: 1,
    photo_category_id: null,
    banner_image: '',
    file_description: '',
    start_event: 0,
    end_event: 0,
    location_known: false,
    location: '',
    address: '',
    city: '',
    lat: null,
    lng: null,
    register: false,
    open_guests: false,
    register_open: 0,
    register_closed: 0,
    costs: 0,
    adaptive_price: false,
    max_registrations: 0,
    use_payment: 0,
    form_id: 0,
    ...props.event
})

if (eventForm.form_id == null) {
    eventForm.form_id = 0;
}

const errors = reactive([]);
const location_search = ref('');
const map_initialised = ref(false);

const startEventDate = computed({
    get() {
        return eventForm.start_event * 1000;
    },
    set( newValue ) {
        eventForm.start_event = newValue / 1000;
    }
});

const endEventDate = computed({
    get() {
        return eventForm.end_event * 1000;
    },
    set( newValue ) {
        eventForm.end_event = newValue / 1000;
    }
});

const openRegisterDate = computed({
    get() {
        return eventForm.register_open * 1000;
    },
    set( newValue ) {
        eventForm.register_open = newValue / 1000;
    }
});

const closeRegisterDate = computed({
    get() {
        return eventForm.register_closed * 1000;
    },
    set( newValue ) {
        eventForm.register_closed = newValue / 1000;
    }
});

const formsSelect = computed(() => {
    return props.forms?.filter(i => i.adaptive == eventForm.adaptive_price)
})

const validateEndEvent = (rule: any, value: any, callback: any) => {
    if (value !== 0 && value !== null && value <= eventForm.start_event) {
        callback(new Error("Einddatum moet later zijn dan de startdatum"));
    } else {
        callback();
    }
};

const validateRegisterClosed = (rule: any, value: any, callback: any) => {
    if (eventForm.register && (value <= eventForm.register_open)) {
        callback(new Error("Datum moet later zijn dan de 'registratie open' datum"));
    } else {
        callback();
    }
};

const validateFormSelect = (rule: any, value: any, callback: any) => {
    if (!eventForm.adaptive_price && value === 0) {
        callback();
    }

    if (!formsSelect.value.find((x) => x.id === value)) {
        callback(new Error("Selecteer een formulier " + (eventForm.adaptive_price ? "met" : "zonder") + " een 'Radio prijs' veld"));
    } else {
        callback();
    }
};

const rules = reactive<FormRules>({
    name: [
        { required: true, message: "Vul de naam in van het evenement", trigger: "blur"},
        { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" }
    ],
    extra_name: [
        { required: true, message: "Vul de ondertitel in van het evenement", trigger: "blur"},
        { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" }
    ],
    description: [
        { required: true, message: "Vul een beschrijving in van het evenement", trigger: "blur"}
    ],
    category_id: [
        { required: true, message: "Selecteer een categorie", trigger: "blur"}
    ],
    start_event: [
        { type: "number", required: true, message: "Kies een startdatum", trigger: "change" },
        { type: "number", min: 1, message: "Kies een startdatum", trigger: "blur" }
    ],
    end_event: [
        { validator: validateEndEvent, trigger: "change" }
    ],
    location: [
        { required: true, message: "Vul de naam van de locatie in", trigger: "blur" }
    ],
    address: [
        { required: true, message: "Vul een geldig adres in in de zoekbalk van Google Maps", trigger: "blur" }
    ],
    city: [
        { required: true, message: "Vul een adres in in de zoekbalk van Google Maps", trigger: "blur" }
    ],
    register_open: [
        { required: true, message: "Kies een datum", trigger: "blur" },
        { type: "number", min: 1, message: "Kies een datum", trigger: "blur" }
    ],
    register_closed: [
        { validator: validateRegisterClosed, trigger: "change" },
        { type: "number", min: 1, message: "Kies een datum", trigger: "blur" }
    ],
    costs: [
        { required: true, message: "Vul een prijs in", trigger: "blur"},
        { type: "number", min: 0, message: "Moet een positief getal zijn", trigger: "blur" }
    ],
    adaptive_price: [
        { required: true, message: "Kies een optie", trigger: "blur" }
    ],
    max_registrations: [
        { required: true, message: "Vul een maximum aantal in", trigger: "blur" },
        { type: "number", min: 0, message: "Moet een positief getal zijn", trigger: "blur" }
    ],
    use_payment: [
        { required: true, message: "Kies een optie", trigger: "blur" }
    ],
    form_id: [
        { required: true, message: "Kies een optie", trigger: "blur" },
        { validator: validateFormSelect, trigger: "change"}
    ]
})

if (props.event && props.event.id) {
    if (props.event.location_known) {
        location_search.value = props.event.address + ', ' + props.event.city

        initMap()
    }
}

Flmngr.load({
    apiKey: flmngrApiKey, // default free key
});

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    await formEl.validate((valid, fields) => {
        if (valid) {
            if (eventForm.id === null) {
                http.post('/agenda', eventForm)
                    .then(response => {
                        savedSuccesNotification();

                        // Set current url to edit
                        let newUrl = window.location.origin + "/agenda/" + response.id + "/bewerken";
                        history.replaceState({}, '', newUrl);

                        eventForm.id = response.id;
                    })
                    .catch(() => {return false})
            } else {
                http.put('/agenda/' + eventForm.id, eventForm)
                    .then(() => savedSuccesNotification())
                    .catch(() => {return false})
            }
        } else {
            return false;
        }
    });
}

function savedSuccesNotification() {
    ElNotification({
        title: 'Opgeslagen',
        message: 'Evenement succesvol opgeslagen!',
        type: 'success'
    });
}

function changeLocationKnown(value: boolean) {
    if (value && !map_initialised.value) {
        initMap();
    } else if (!value) {
        map_initialised.value = false;
    }

    return true;
}

function openFileManager(type = 'images') {
    let urlFileManager = 'https://admin.voxvivarotterdam.nl/agenda/bestanden/afbeeldingen';
    let urlFiles = '/storage/agenda/images';

    if (type == 'files') {
        urlFileManager = 'https://admin.voxvivarotterdam.nl/agenda/bestanden/documenten';
        urlFiles = '/storage/agenda/files';
    }

    Flmngr.open({
        urlFileManager: urlFileManager, // server
        urlFiles: urlFiles, // file storage
        isMultiple: false,
        showMaximizeButton: false,
        onFinish: (files) => {
            if (type == 'images') {
                eventForm.banner_image = files[0].url;
            } else if (type == 'files') {
                eventForm.file_description = files[0].url;
            }
        }
    });
}

function initMap() {
    const loader = new Loader({
        apiKey: "AIzaSyBAAhWImY-ZImjZsrMxVgCkR8KZR6J5hww",
        version: "weekly",
        libraries: ["places"]
    });
    
    loader.load().then((google) => {
        const mapElement = document.getElementById('map');
        const inputField = document.getElementById('pac-input');
        const infowindow = new google.maps.InfoWindow();
        const infowindowContent = document.getElementById("infowindow-content");

        const map = new google.maps.Map(mapElement, {
            zoom: (eventForm.lat ? 17 : 11),
            disableDefaultUI: true,
            gestureHandling: 'none',
            mapId: '444a54f0ee34eeb0',
            center: {
                lat: (eventForm.lat ? eventForm.lat : 51.9225),
                lng: (eventForm.lng ? eventForm.lng : 4.47917)
            }
        });

        const marker = new google.maps.Marker({
            map: map,
            anchorPoint: new google.maps.Point(0, -29)
        });

        if (eventForm.lat) {
            marker.setPosition({
                    lat: eventForm.lat,
                    lng: eventForm.lng
            });
            marker.setVisible(true);
        }

        const options = {
            fields: ['formatted_address', 'address_components', 'geometry', 'icon', 'name'],
            locationBias: {
                north: 53.5104033474,
                south: 50.803721015, 
                west: 3.31497114423, 
                east: 7.09205325687,
            }
        }

        let autocomplete = new google.maps.places.Autocomplete(inputField, options);
        infowindow.setContent(infowindowContent);

        map_initialised.value = true;

        autocomplete.addListener("place_changed", () => {
            infowindow.close();
            marker.setVisible(false);

            const place = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                window.alert("No details available for input: '" + place.name + "'");
                return;
            }

            location_search.value = place.formatted_address;

            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);
            }

            marker.setPosition(place.geometry.location);
            marker.setVisible(true);

            setAddress(place);

            if (infowindowContent) {
                infowindowContent.children['place-icon'].src = place.icon;
                infowindowContent.children['place-name'].textContent = place.name;
                infowindowContent.children['place-address'].textContent = place.formatted_address;
            }
            
            infowindow.open(map, marker);
        });
    });
}

function setAddress(place) {
    let straat, huisnummer = ''
    eventForm.address = '';
    eventForm.city = '';
    eventForm.lat = null;
    eventForm.lng = null;

    place.address_components.forEach((value) => {
        if (value.types.includes("route")) {
            straat = value.long_name;
        } else if (value.types.includes("street_number")) {
            huisnummer = value.long_name;
        } else if (value.types.includes("locality")) {
            eventForm.city = value.long_name;
        }
    });

    if(typeof straat !== 'undefined') {
        if (typeof huisnummer !== 'undefined') {
            eventForm.address = straat + ' ' + huisnummer;
        } else {
            eventForm.address = straat;
        }
    }

    eventForm.lat = place.geometry.location.lat();
    eventForm.lng = place.geometry.location.lng();
}
</script>
<template>
    <div>
        <button class="btn btn-primary" role="button" @click="showDialog = true">Registratie toevoegen</button>

        <el-dialog
            title="Registratie toevoegen"
            width="500px"
            :show-close="true"
            :close-on-click-modal="false"
            :model-value="showDialog"
            :before-close="handleClose">
            <el-select v-model="selectedMember" filterable placeholder="Select" style="width: 100%;">
                <el-option
                    v-for="member in members"
                    :key="member.id"
                    :label="member.name"
                    :value="member.id"
                />
            </el-select>

            <template #footer>
                <el-button @click="handleClose()">Annuleren</el-button>
                <el-button type="primary" @click="addRegistration()">Toevoegen</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import type { Ref } from 'vue';
import { ElNotification } from 'element-plus'
import http from "../../libs/axios"

const props = defineProps({
    'event': Object,
    'registrations': Array<[]>,
    'members': Array<[]>
})

const selectedMember: Ref<number | undefined> = ref()
const showDialog = ref(false)

function addRegistration () {
    if (!selectedMember.value || selectedMember.value == null || selectedMember.value == 0) {
        ElNotification({
            title: 'Er ging iets fout',
            message: 'Selecteer een persoon',
            type: 'error'
        })

        return false
    }

    http.post('/agenda/'+ props.event?.id +'/registraties/' + selectedMember.value)
        .then(() => {
            savedSuccesNotification()
            handleClose()

            setTimeout(() => {
                document.location.reload()
            }, 500)
        })
        .catch(() => {return false})
}

function handleClose() {
    selectedMember.value = undefined
    showDialog.value = false
}

function savedSuccesNotification() {
    ElNotification({
        title: 'Opgeslagen',
        message: 'Registratie succesvol toegevoegd!',
        type: 'success'
    })
}
</script>
import { createApp, h } from 'vue'
import { convertDataSet } from './helper'
import createNotification from '../components/notification/createNotification.vue'


if (document.getElementById("createNotification")) {
    const app5 = createApp({
        render() {
          return h(createNotification, convertDataSet('createNotification'))
        }
    })
    
    app5.mount('#createNotification')
  }
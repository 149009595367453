import { createApp, h } from 'vue'
import { convertDataSet } from './helper'
import createMagazine from '../components/magazine/createMagazine.vue'

if (document.getElementById("createMagazine")) {
    const app1 = createApp({
        render() {
          return h(createMagazine, convertDataSet('createMagazine'))
        }
    })
    
    app1.mount('#createMagazine')
}

<template>
    <div>    
        <vue3-datatable 
            :rows="product.purchase_items"
            :columns="cols"
            :totalRows="product.purchase_items?.length"
            :columnFilter="false"
            :sortable="true"
            :pagination="false"
            :stickyHeader="true"
            sortColumn="id"
            sortDirection="desc"
            class="column-filter"
            skin="bh-table-compact bh-table-bordered"
            v-if="product"
        >
            <template #created_at="data">
                {{ formatDate(data.value.created_at) }}
            </template>
            <template #options="data">
                <span v-for="(option, key) in data.value.options"><b>{{key}}:</b> {{ option }}<br/></span>
            </template>
            <template #delivered_at="data">
                {{ formatDate(data.value.delivered_at) }}
            </template>
            <template #status="data">
                <el-tag round :effect="data.value.status.effect" :type="data.value.status.type">{{ data.value.status.label }}</el-tag>
            </template>
            <template #price="data">
                &euro;{{ data.value.price }}
            </template>
            <template #view="data">
                <el-dropdown trigger="click">
                    <i class="mdi mdi-dots-vertical"></i>
                    <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item v-if="data.value.status.can_deliver" @click="deliverItem(data.value.id)">Aankoop geleverd</el-dropdown-item>
                        <el-dropdown-item v-if="data.value.status.can_refund" @click="refundItem(data.value.id)">Stort geld aankoop terug</el-dropdown-item>
                        <el-dropdown-item v-if="data.value.status.can_delete" @click="deleteItem(data.value.id)">Verwijder aankoop</el-dropdown-item>
                        <el-dropdown-item v-else-if="!data.value.status.can_deliver && !data.value.status.can_refund && !data.value.status.can_delete" disabled>Geen acties mogelijk</el-dropdown-item>
                    </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
        </vue3-datatable>
    </div>
</template>
 
<script setup lang="ts">
import { ref } from 'vue'
import Vue3Datatable from '@bhplugin/vue3-datatable'
import http from "../../libs/axios"
import moment from 'moment'

const props = defineProps({
    'product': Object
})

const search = ref('')

const cols = ref([
    { field: 'created_at', title: 'Datum', order: 'desc', sort: true},
    { 
        field: 'purchase.user.name',
        title: 'Naam',
        cellRenderer: (item: any) => {
            return (
                '<a href="/leden/'+item.purchase.user.id+'" target="_blank">' +
                item.purchase.user.name +
                '</a>'
            )
        },
        sort: false
    },
    { field: 'options', title: 'Opties', sort: false, filter: false },
    { field: 'delivered_at', title: 'Geleverd op', filter: false },
    { field: 'status', title: 'Status'},
    { field: 'price', title: 'Bedrag', type: 'number', sort: false, filter: false },
    { field: 'view', title: '', headerClass: 'mdi mdi-cog', filter: false, sort: false },
]) || []

const deliverItem = async (itemId: number) => {
    await http.put('/producten/aankopen/geleverd/' + itemId, {})
        .then(() => {
            savedSuccesNotification('Voorraaditem geleverd.')
            window.location.reload()
        })
        .catch(() => {return false})
}

const refundItem = async (itemId: number) => {
    await http.post('/producten/aankopen/terugbetalen/' + itemId, {})
        .then(() => {
            savedSuccesNotification('Voorraaditem terugbetaald.')
            window.location.reload()
        })
        .catch(() => {return false})
}

const deleteItem = async (itemId: number) => {
    await http.delete('/producten/aankopen/' + itemId, {})
        .then(() => {
            savedSuccesNotification('Voorraaditem verwijderd.')
            window.location.reload()
        })
        .catch(() => {return false})
}

function savedSuccesNotification(message: string) {
    ElMessage({
        title: 'Opgeslagen',
        message: message,
        type: 'success'
    })
}

function formatDate (date: string) {
    if (!date || date == '')
        return '-';
    return moment(date).format('DD/MM/YYYY - HH:mm');
}

</script>
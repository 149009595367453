import axios from 'axios';
import { ElNotification } from 'element-plus';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Response
axios.interceptors.response.use(
    (response) => {
        if (response.data.data) {
            return response.data.data;
        } else {
            return response;
        }
    },
    (error) => {
        if (!error.response.data.message) {
            ElNotification.error({
                title: 'Error',
                message: 'Er ging iets fout!'
            });
        } else {
            ElNotification.error({
                title: 'Error',
                message: error.response.data.message
            });
        }

        return Promise.reject(error);
    }
);

export default axios;
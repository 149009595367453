<template>
    <div>
        <el-form-item>
            <el-select v-model="type" @change="fetchUsers()">
                <el-option value="all" label="Alle leden"/>
                <el-option value="active" label="Actieve leden"/>
                <el-option value="inactive" label="Inactieve leden"/>
                <el-option value="guests" label="Gasten"/>
            </el-select>
        </el-form-item>
        <vue3-datatable 
            :rows="rows"
            :columns="cols"
            :totalRows="rows?.length"
            :columnFilter="true"
            :sortable="true"
            :showFirstPage="false"
            :showLastPage="false"
            sortColumn="id"
            sortDirection="desc"
            class="column-filter"
        >
            <template #id="data">
                <strong>#{{ data.value.id }}</strong>
            </template>
            <template #view="data">
                <div class="flex gap-4">
                    <button class="btn btn-success" @click="goTo(data.value.id)">View</button>
                </div>
            </template>
        </vue3-datatable>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import Vue3Datatable from '@bhplugin/vue3-datatable'
import http from "../../libs/axios"

const cols = ref([
    { field: 'id', title: 'ID', isUnique: true, filter: false, type: "number" },
    { field: 'name', title: 'Naam' },
    { field: 'email', title: 'E-mailadres' },
    { field: 'phone', title: 'Telefoonnummer', type: 'number' },
    { field: 'view', title: 'Actions', filter: false },
]) || [];

const rows = ref();
const type = ref('active');

onMounted(async () => {
    await fetchUsers()
})

async function fetchUsers()
{
    await http.get('/leden/' + type.value)
        .then((response) => {rows.value = response})
        .catch(() => {return false})
}

function goTo(userId: number)
{
    window.location.href = "/leden/" + userId;
}

</script>

<style>
    .column-filter table {
        @apply min-h-[380px];
    }
</style>
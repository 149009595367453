import { createApp, h } from 'vue'
import { convertDataSet } from './helper'

import CreateCommittee from '../components/committee/createCommittee.vue'

if (document.getElementById("createCommitteeForm")) {
    const app1 = createApp({
        render() {
          return h(CreateCommittee, convertDataSet('createCommitteeForm'))
        }
    })
    
    app1.mount('#createCommitteeForm')
}
import { createApp, h } from 'vue'
import { convertDataSet } from './helper'
import createProduct from '../components/product/createProduct.vue'
import stockProduct from '../components/product/stock.vue'
import purchasesProduct from '../components/product/purchases.vue'

import discountCodes from '../components/discount_codes/discountCodes.vue'
import addDiscountCode from '../components/discount_codes/addDiscountCode.vue'

if (document.getElementById("createProduct")) {
    const app1 = createApp({
        render() {
          return h(createProduct, convertDataSet('createProduct'))
        }
    })
    
    app1.mount('#createProduct')
}

if (document.getElementById("stockProduct")) {
    const app2 = createApp({
        render() {
          return h(stockProduct, convertDataSet('stockProduct'))
        }
    })
    
    app2.mount('#stockProduct')
}

if (document.getElementById("purchasesProduct")) {
  const app3 = createApp({
      render() {
        return h(purchasesProduct, convertDataSet('purchasesProduct'))
      }
  })
  
  app3.mount('#purchasesProduct')
}

if (document.getElementById("discountCodes")) {
  const app4 = createApp({
      render() {
        return h(discountCodes, convertDataSet('discountCodes'))
      }
  })
  
  app4.mount('#discountCodes')
}

if (document.getElementById("addDiscountCode")) {
  const app5 = createApp({
      render() {
        return h(addDiscountCode, convertDataSet('addDiscountCode'))
      }
  })
  
  app5.mount('#addDiscountCode')
}
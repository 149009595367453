import { createApp, h } from 'vue'
import { convertDataSet } from './helper'

import EditPage from '../components/page/editPage.vue'

if (document.getElementById('editPageForm')) {
  const app1 = createApp({
    render() {
      return h(EditPage, convertDataSet('editPageForm'))
    }
  })

  app1.mount('#editPageForm')
}

import { createApp, h } from 'vue'
import { convertDataSet } from './helper'

import NewRegistrationsChart from '../components/home/newRegistrationsChart.vue'

if (document.getElementById("newRegistrationsChart")) {
    const app1 = createApp({
        render() {
          return h(NewRegistrationsChart, convertDataSet('newRegistrationsChart'))
        }
    })
    
    app1.mount('#newRegistrationsChart')
}
import app from './main'

import '@mdi/font/css/materialdesignicons.min.css'

import './libs/bootstrap-sass'
import './libs/theme'
import '@bhplugin/vue3-datatable/dist/style.css';
import './custom'
import '../sass/admin.scss'

import './pages/home'
import './pages/achievement'
import './pages/committee'
import './pages/event'
import './pages/form'
import './pages/page'
import './pages/photo'
import './pages/product'
import './pages/magazine'
import './pages/user'
import './pages/notification'

app.mount(document.createElement('div'))